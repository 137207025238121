export const mapQueryParams = (existingQueryParams: any) => {
  if (!existingQueryParams.searchkeyword && existingQueryParams.search) {
    // ! To manager ROR params
    existingQueryParams.searchkeyword = existingQueryParams.search;
  }

  if (!existingQueryParams.joblocations && existingQueryParams.location) {
    // ! To manager ROR params
    existingQueryParams.joblocations = existingQueryParams.location;
  }

  /**
   * ! NOTE:
   *  'search' and 'location' are the query params from ROR pages and we are maping that query params to our query params
   */

  // Below searchJobtitle|jobScheduleId are the dyanamic page routes
  // As it get's append in router.query
  existingQueryParams = removeItemsFromFilterObj(
    [
      'useCanonicalTwo',
      'isJobTypePage',
      'isSchedulePage',
      'searchJobtitle',
      'jobtypesId',
      'jobScheduleId',
      'category',
      'locationJobs',
      'locationRoot',
      'locationCountry',
      'locationChild',
      'searchPage',
      'search',
      'query',
      'queryval',
      'location',
    ],
    existingQueryParams
  );

  const updatedFilters: any = {};
  Object.entries(existingQueryParams)?.forEach(([key, value = '']) => {
    if (value) {
      updatedFilters[key.toLowerCase()] = Array.isArray(value)
        ? value
        : [value];
    }
  });

  return updatedFilters;
};

export const removeItemsFromFilterObj = (keysToRemove: string[], obj: any) => {
  for (const key of keysToRemove) {
    if (key in obj) {
      delete obj[key];
    }
  }
  return obj;
};

type SingleFilterArrayType = {
  [key: string]: Array<string>;
};

type SelectedFiltersType = SingleFilterArrayType;

export const queryStringManipulation = (tempArr: SelectedFiltersType) => {
  let queryString: string = getFilterQueryString(tempArr);

  // Below searchJobtitle|jobScheduleId are the dyanamic page routes
  queryString = queryString?.replace(
    /(\?|&)(company_name|jobfeed|jobtypesId|searchJobtitle|jobScheduleId|category|locationRoot|locationChild)=[^&]*/g,
    ''
  );

  if (queryString.startsWith('&') || queryString.startsWith('?&')) {
    queryString = queryString?.replace(/^\?&+/g, '?')?.replace(/^&+/g, '?');
  }
  return queryString;
};

export const getFilterQueryString = (query: object = {}) => {
  let queryString: string = '?';
  Object.entries(query)?.forEach(([key, value]) => {
    // Length check to avoid [''] values
    if (value && value.length && value[0]) {
      Array.isArray(value)
        ? value.map((item: string) => {
            queryString = queryString + `${key}=${encodeURIComponent(item)}&`;
          })
        : (queryString =
            queryString + `${key}=${encodeURIComponent(value || '')}&`);
    }
  });
  return queryString;
};
