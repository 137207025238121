import {
  IJobType,
  IReportJob,
  JobsTabs,
  UserTabs,
} from '@components/shared.types';
import { isPortalContent } from 'helper';

export const agreementDataForUser = [
  {
    agreementCD: 'PRYPCY', // Privacy Policy
    isAgreed: 'true',
  },
  {
    agreementCD: 'TMOUSR', // Terms of Use
    isAgreed: 'true',
  },
];
export const agreementDataForPartner = [
  {
    agreementCD: 'SHACTV', // Partner Shared Data
    isAgreed: 'true',
  },
];

export const LOCAL_STORAGE_CONSTS: Record<string, string> = {
  userPreferences: 'userPreferences',
};

export const selectedFiltersOrder = [
  'searchkeyword',
  'remoteoptions',
  'jobtypes',
  'jobschedules',
  'careerlevel',
  'educations',
  'travels',
  'categories',
  'accolades',
  'companies',
  'jobtitles',
];

export const staticListConst = {
  anywhereus: 'Anywhere in U.S.',
  anywhereworld: 'Anywhere in the World',
};

export const searchStaticList = [
  {
    icon: 'far fa-location-dot',
    name: 'Current Location',
    border: true,
    href: '/remote-jobs/search',
  },
  {
    imageIcon:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAjCAYAAADxG9hnAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIzSURBVHgBzViLVdswFL3pYYB0gqoTtBtUnYB2gqYTOJ0g7gTABLABMIGzATCBwwTJBkH3+Cl+lmNsKcZwz7mJ/CRLV09PPwMfBDPEYe64cPzm+N3RiI3YOW6E945rSY8K61g47iN5K++eDJMo4Jggg0RkjtsRRHiyriUisQoquXYsA9tNoqDVQA0tEQ+oAtKg9pDvWSbPtoeXsWIydPfiOhCXo46fIciDOjuHySA9JoYiRzNmjM84U4UuUK8JHo+o1os+W4F4sC16+ac2WrR7eSkN+vFlD36h6sUDxptNbBufREh2RLGR/y+qB+QObY+cghV/ZtJgeaTATjU876nsK+LhZyPxmT8LDFuMxghWjVy9v+TQnKtM9p7B+E/S3nYldo+N419VJgVrleYm2go8i8pl3l7KM+3hgrZUZVJ4WJc4fU2gNEMzII3YDOox/eP4Q70T1hELM0P6GI8O754C0+MQFgxWH3AG00Of7hrBOrWIQ7DSI08qcz8ht6rdZwp5xPvjjrOGLtLqNpgGRqUPWwRnTGM3fGMsVHt3OsOqjBL9m9wpmKO5qloa9QWrQO0NnkGu8DbgKu23CHrjd1jAYNzrw5BZY9CB5YRCGofns0AIh4RjuArsa6SD9YUnuv/SVi9yNNWXqCI9Fgu0L2Y5IkHXbY8Iov21M6tF5dHw3aQrp4dBdYHuGmfuU4WwRHewFxhpU7U9gvavCLBDGoj9UGOk4nNJk+GHGu5d3EhvEHGmfQGea5J5WoCZ+wAAAABJRU5ErkJggg==',
    name: staticListConst.anywhereus,
    extraTopSpace: true,
    href: '/remote-jobs/search',
  },
  {
    imageIcon:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAjCAYAAADxG9hnAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALaSURBVHgBzVeBcdswDPxkgXqDcARnA3aCaoOoE1SdwOwEcSewN3A2UDuB2gnkDZwNXCIGSogmKbFJ7/J3uJgkAD4BAmKAd4Ib1GHlZc1yx2PBs5ffXn6x/BdYLzsvJy/nBTKyvsEbwXrpo01OLM5L52Xw0iq9mOyrCT1GmzvecFCODRMQEDGKRuNlj2mEWlRihWkUtjxnIhIpIkCIjqwflK8NKkgMCKewao3G60jfJIiAyXdq7GrJ6DxbNd/ikmssJEIHGjGtKp3qDgVsECIhuT4wiRHpC5cjQnC4kN+wTo9pZKxWvlEOR/59j9AH1kzkE4+PCP1C8ODlOy6n/8C+JIU099XLE9sRKG1f2Ne9mn+BpMThGnSqRjm2PG4xLd+W5w1CSnpEJ2cMqf0sQkpMwijOtYZBPjVgci4xbxHu4l9sWZp/2EjKWn7Tfdgh9AyaO2RsJQtWb5ZDwyTnQHci7qiW13YZmw6h80KTkZOYyMCgngSJRJgicpewM4jSQxNj5ETKdg45EmeEA+xx3QgFYvtCtEP+K0qKFClbScIpPfrdZIhI9VitfMayT7utICG+W6SxZ5tWP4woMo94Hb4hEGlxaXaGx8eEvmH5HC/QSeP7slQc0nDIR6Rn2+Y2WqDW/hH1Tz0diRjUS46FNcIzCqD+sSQSTyiDKtBm1sTHCjPoFhA5zTjqM+uW7alycIsyfmAmbLxJU1g3GR8P/PcnZlAq0Vj6go9U6uThdEa+2VWTKOWZUtsm5je5A5CyZQK2QOJQICLPP4PwLumR/naJf6sXGtT1CYdyeigyVHEDwieiVftJW98hAbeQhGCP+fQIAcv6Pab/IRhksF1IAphetpSuQXgDa2JS7gYFtAnHQ0Hf4Pou6fRYpdcrEsUq0US083HGxuKavH7Bb5S/EQtIQLEnOOV4DnH3NTzfY1pts208B4fyC15jzxu2PDYIqbJ4A9ScQoe9qSXwB5DLhQroXZ/YAAAAAElFTkSuQmCC',
    name: staticListConst.anywhereworld,
    border: true,
    extraBottomSpace: true,
    href: '/remote-jobs/search',
  },
];

export const JOB_TYPES: { [key: string]: IJobType } = {
  PAST: 'past',
  CURRENT: 'current',
};

export const JOBS_TABS: JobsTabs = [
  {
    id: 'current',
    title: 'Current Jobs',
  },
  // {
  //   id: 'past',
  //   title: 'Past Jobs',
  // },
];

export const USER_TABS: UserTabs = [
  {
    id: 'getStarted',
    title: 'Get Started',
  },
  {
    id: 'logIn',
    title: 'Log In',
  },
];

export const Regex = {
  email: /^\w+([-+.']\w+)*@\w+([-.']\w+)*\.\w+([-.]\w+)*$/,
  emailDomainRegex: /@(gmail|me|mac|yahoo|hotmail|aol)\.com/,
  comapnayWebsite: /^https:\/\//,
  getPage: /page=(\d+)/,
  // allowed first and last word with only aA-zZ and space, hypen and single inverted comma in between
  name: /^[a-zA-Z]?[- 'a-zA-Z]+$/,
  capitilizeAfterSpace: /(^\w{1})|(\s+\w{1})/g,
  testLogoPattern: /^\/assets\/images\/storage\/.*$/,
};

type IAllowedSectionsToHide = {
  CATEGORY_TABS: string;
  SEARCH_FILTER: string;
  SURPRISING_JOBS_FILTER: string;
  WIDGETS: string;
  JOB_CARDS: string;
};

export const allowedSectionsToHide: IAllowedSectionsToHide = {
  CATEGORY_TABS: 'categoryTabs',
  SEARCH_FILTER: 'searchFilter',
  SURPRISING_JOBS_FILTER: 'suprisingJobsFilter',
  WIDGETS: 'widgets',
  JOB_CARDS: 'jobCards',
} as const;

type CompanyTabs = {
  id: string;
  title: string;
  icon: string;
}[];

export const savedJobsField = 'saved-jobs';

export const COMPANY_TABS: CompanyTabs = [
  {
    id: 'job-summary',
    title: 'Job Summary',
    icon: 'fas fa-list',
  },
  {
    id: 'company',
    title: 'Company',
    icon: 'fa-regular fa-building fa-fw',
  },
];

export const HOSTED_JOBS_TABS: CompanyTabs = [
  {
    id: 'job-summary',
    title: 'Job',
    icon: 'fa fa-briefcase',
  },
  // {
  //   id: 'company',
  //   title: 'Company',
  //   icon: 'fa-regular fa-building fa-fw',
  // },
];

export const ApplyJobStatus = {
  applied: 'applied',
  sentFollowUp: 'sentfollowup',
  interviewSchedule: 'interviewschedule',
  interviewCompleted: 'interviewcompleted',
  offerAccepted: 'offeraccepted',
  offerDeclined: 'offerdeclined',
};
export const AboutJobs = [
  {
    name: `About ${isPortalContent} `,
    children: [
      {
        name: `How ${isPortalContent}  Works`,
        route: '/About.aspx',
      },
      {
        name: 'What Is A Remote Job?',
        route: '/blog/post/what-is-a-remote-job',
      },
      {
        name: 'Benefits of Working Remotely',
        route: '/blog/post/benefits-of-remote-work',
      },
      {
        name: 'Finding Remote Jobs',
        route: '/blog/post/how-to-find-a-remote-job-v2',
      },
      {
        name: 'Remote Work Statistics',
        route: '/blog/post/remote-work-statistics',
      },
      {
        name: 'Press & Awards',
        route: '/PressReviews.aspx',
      },
      {
        name: `The ${isPortalContent}  Team`,
        route: '/FlexJobsTeam.aspx',
      },
      {
        name: `Careers at ${isPortalContent}`,
        route: 'https://www.bold.com/bold-jobs',
      },
      {
        name: `${isPortalContent} App`,
        route: '/job-search-app',
      },
      {
        name: `${isPortalContent} FAQ`,
        route: '/faq',
      },
      // {
      //   name: 'Gift Certificates',
      //   route: '/giftcertificates',
      // },
      {
        name: 'Affiliate Program',
        route: '/blog/flexjobs-affiliate-program-2',
      },
      // {
      //   name: 'Do Not Sell or Share My Personal Information',
      //   route: '/dnss-form',
      // },
      {
        name: 'Contact Us',
        route: '/contact',
      },
      {
        name: 'Fraud Awareness',
        route: '/fraud-awareness',
      },
      {
        name: `${isPortalContent} Reviews`,
        route: '/reviews-success-stories',
      },
    ],
  },
][0];

export const AboutJobsAuth = [
  {
    name: `About ${isPortalContent}`,
    children: [
      {
        name: `How ${isPortalContent} Works`,
        route: '/About.aspx',
      },
      {
        name: 'What Is A Remote Job?',
        route: '/blog/post/what-is-a-remote-job',
      },
      {
        name: 'Benefits of Working Remotely',
        route: '/blog/post/benefits-of-remote-work',
      },
      {
        name: 'Finding Remote Jobs',
        route: '/blog/post/how-to-find-a-remote-job-v2',
      },
      {
        name: 'Remote Work Statistics',
        route: '/blog/post/remote-work-statistics',
      },
      {
        name: 'Press & Awards',
        route: '/PressReviews.aspx',
      },
      {
        name: `The ${isPortalContent} Team`,
        route: '/FlexJobsTeam.aspx',
      },
      {
        name: `Careers at ${isPortalContent}`,
        route: 'https://www.bold.com/bold-jobs',
      },
      {
        name: `${isPortalContent} App`,
        route: '/job-search-app',
      },
      {
        name: 'Affiliate Program',
        route: '/blog/flexjobs-affiliate-program-2',
      },
      {
        name: 'Do Not Sell or Share My Personal Information',
        route: '/dnss-form',
      },
      {
        name: 'Contact Us',
        route: '/contact',
      },
      {
        name: 'Fraud Awareness',
        route: '/fraud-awareness',
      },
      {
        name: `${isPortalContent} Reviews`,
        route: '/reviews-success-stories',
      },
      {
        name: 'CCPA/GDPR',
        route: '/personal-data',
      },
    ],
  },
][0];

export const AboutJobsAuthReg = [
  {
    name: `About ${isPortalContent}`,
    children: [
      {
        name: `How ${isPortalContent} Works`,
        route: '/About.aspx',
      },
      {
        name: 'What Is A Remote Job?',
        route: '/blog/post/what-is-a-remote-job',
      },
      {
        name: 'Benefits of Working Remotely',
        route: '/blog/post/benefits-of-remote-work',
      },
      {
        name: 'Finding Remote Jobs',
        route: '/blog/post/how-to-find-a-remote-job-v2',
      },
      {
        name: 'Remote Work Statistics',
        route: '/blog/post/remote-work-statistics',
      },
      {
        name: 'Press & Awards',
        route: '/PressReviews.aspx',
      },
      {
        name: `The ${isPortalContent} Team`,
        route: '/FlexJobsTeam.aspx',
      },
      {
        name: `Careers at ${isPortalContent}`,
        route: 'https://www.bold.com/bold-jobs',
      },
      {
        name: `${isPortalContent} App`,
        route: '/job-search-app',
      },
      {
        name: `${isPortalContent} FAQ`,
        route: '/faq',
      },
      {
        name: 'Affiliate Program',
        route: '/blog/flexjobs-affiliate-program-2',
      },
      {
        name: 'Do Not Sell or Share My Personal Information',
        route: '/dnss-form',
      },
      {
        name: 'Contact Us',
        route: '/contact',
      },
      {
        name: 'Fraud Awareness',
        route: '/fraud-awareness',
      },
      {
        name: `${isPortalContent}  Reviews`,
        route: '/reviews-success-stories',
      },
      {
        name: 'CCPA/GDPR',
        route: '/personal-data',
      },
    ],
  },
][0];

export const JobSearch = [
  {
    name: 'Job Search Resources',
    children: [
      {
        name: 'Stay at Home Jobs for Moms and Dads',
        route: '/blog/post/10-great-work-home-jobs-stay-home-moms',
      },
      {
        name: 'Side Hustle Jobs from Home',
        route: '/blog/post/remote-side-jobs-done-from-home-hiring-now-2',
      },
      {
        name: 'Work from Home Jobs No Experience',
        route: '/blog/post/how-to-make-money-from-home-no-work-experience',
      },
      {
        name: 'Legitimate Work from Home Jobs',
        route: '/blog/post/companies-with-legitimate-work-from-home-jobs',
      },
      {
        name: 'Easy Remote Jobs',
        route: '/blog/post/easy-remote-work-from-home-jobs-hiring-now',
      },
      {
        name: 'High Paying Remote Jobs',
        route: '/blog/post/top-paying-remote-jobs-v2',
      },
      {
        name: 'How To Make Money Online',
        route: '/blog/post/real-ways-to-make-money-from-home',
      },
      {
        name: 'Great Companies Hiring Remote Workers',
        route: '/blog/post/happiest-companies-hiring-remote-workers',
      },
      {
        name: 'Weekend Jobs',
        route: '/blog/post/top-weekend-jobs-and-companies-hiring-now',
      },
      {
        name: 'Digital Nomad Jobs',
        route: '/blog/post/digital-nomad-jobs-v2',
      },
      {
        name: 'How to Become a Virtual Assistant',
        route: '/blog/post/how-to-work-as-a-virtual-assistant',
      },
      {
        name: 'Informational Guides',
        route: '/resources',
      },
      {
        name: 'Online Resume Builder',
        route: 'https://www.myperfectresume.com/resume/builder',
      },
      {
        name: 'Professional Resume Templates',
        route: 'https://zety.com/resume-templates',
      },
    ],
  },
][0];

export const Resources = [
  {
    name: 'Resources',
    children: [
      {
        name: `${isPortalContent} Employer Blog`,
        route: '/employer-blog',
      },
      {
        name: 'Economic Development',
        route: '/employer-hiring-remote-workers-rural-economic-development',
      },
      // {
      //   name: 'Telecommuting Stats',
      //   route: '/2017-State-of-Telecommuting-US',
      // },
      {
        name: 'Guide to Hybrid Remote Work Success',
        route: '/guide-to-hybrid-remote-work-success',
      },
      {
        name: 'Work Flexibility for Military Families',
        route: '/military',
      },
    ],
  },
][0];

export const CONFIG_OPTIONS = {
  productCD: 'FJS',
  portalCD: 'RMC',
  templateID: '2005',
  portalID: 218,
  cookieDomain: '.remoteco-stg.com',
  portalName: 'remoteco-stg.com',
  appCD_QA: 'RMCDSB_SND_W_COR',
  appCD_REG: 'RMCDSB_REG_W_COR',
  sourceAppCD: 'RJIUS',
  clientCode: 'RMTCO',
  authProvider: 'RMC-COR',
  authCookieName_QA: 'Auth_QA',
  authCookieName_REG: 'Auth_REG',
  authCookieName: 'Auth',
  temaplateID: '2005',
  docTypeCd: 'JPFL',
  cultureCd: 'en-US',
  defaultSkinCd: 'FLJ1',
};

export const preferenceCd = {
  IncompleteFields: 'INCF',
  CompletionScore: 'CMSC',
};

export const getAllDocumentFilter =
  'id,legacyDocId,documentTypeCD,templateID,partyID,userId,skinCD,name,publicName,docStatusTypeCD,userStageID,dateCreated,dateModified,migrationDate,portalID,preferences,country,productCD,version';

export const baseConfig = {
  hideFilters: [''],
  // There order should not be changed
  filtersOrder: [
    'remote',
    'location',
    'salary',
    'jobtype',
    'dateposted',
    'careerlevel',
    'category',
    'benefits',
    'schedule',
    'company',
  ],
  // There order should not be changed
  filtersQueryParamKeys: {
    remote: 'remoteoptions',
    location: 'joblocations',
    salary: 'salaryrange',
    jobtype: 'jobtypes',
    dateposted: 'age',
    careerlevel: 'careerlevel',
    category: 'categories',
    benefits: 'jobbenefits',
    schedule: 'jobschedules',
    company: 'companies',
  },
};

export const ReportJobReason: IReportJob = {
  noLongerAvailable: {
    label: 'This job is no longer available',
    showTextArea: false,
    childText: '',
    showSubmit: true,
  },
  incorrectOrMissing: {
    label: 'Incorrect or missing information',
    showTextArea: true,
    childText: 'Please provide additional details:',
    showSubmit: true,
  },
  troubleApplying: {
    label: 'Trouble applying',
    showTextArea: false,
    childText:
      "Thank you for bringing this to our attention! It's possible the employer site was experiencing issues or downtime when you tried to apply earlier. Please try again, perhaps from a different browser or computer. If you continue to have trouble, you may need to follow up with the employer to troubleshoot the issue with their application form.",
    showSubmit: false,
  },
  jobNotRemote: {
    label: 'Job is not remote',
    showTextArea: false,
    childText: `${isPortalContent} offers more than just remote jobs. Many jobs have different types of flexibility. We provide access to jobs offering various levels of work flexibility, including remote, part-time, freelance, temporary, alternative schedule, etc. If you are specifically interested in remote jobs, try doing a site search and filtering on your results. You can select "100% Remote Work" in the "Remote Work Level" filter on the left-hand side.`,
    showSubmit: false,
  },
  locationRequirement: {
    label: 'Location requirement',
    showTextArea: false,
    childText:
      'Many employers, even with 100% remote positions, prefer a person within a particular city or state. Employers may be limited to specific locations due to local tax requirements, training, or certifications. Use keyword search and location search to find jobs in your area along with US National and Worldwide jobs. Search results can then be filtered for different options, like schedule or remote work level.',
    showSubmit: false,
  },
  other: {
    label: 'Other',
    showTextArea: true,
    childText: 'Please describe:',
    showSubmit: true,
  },
};

export const _CONSTANTS = {
  LAST_SEARCH_TERM: 'lastSearchTerm',
  SESSION_ID: '_session_id',
  USER_STATUS: 'UserStatus',
  USER_AUTH_SUCCESS: 'SUCCESS',
  USER_AUTH_USER_SWAP: 'USER_SWAP',
  VSUID: 'vsuid',
  COMMON: 'common',
  CLAIMS: 'claims',
  SHOW_SAVE_SEARCH_BANNER: 'SHOW_SAVE_SEARCH_BANNER',
  SET_SHOW_ALL_DROP_FILTERS: 'showAllDropFilters',
  EXIT_POP_DISMISSED_KEY: 'fjLSflexer-exit-pop-dismissed',
  FLEXJOBS_DIFFERENT_POP_UP_KEY: 'fj_different_pop_dismissed',
  CCPA_STATUS: 'CCPA_STATUS',
  TEST_COUNTRY: 'testcountry',
  CLEAR_COOKIE: 'clearCookie',
  CONDUCT_EXPERIMENT: 'c_expt',
  LAYER_ID: 'e_layer',
  SOFT_REG_POPUP_DISPLAYED: 'softRegPopupDisplayed',
  JOB_SEARCH_STEPS_POPUP_DISPLAYED: 'jobSearchStepsPopupDisplayed',
  VSTR: 'vstr',
  JOBS_COUNT: 'jobs_type_count',
  BIG_INTERVIEW: 'big_interview',
  COMPANY_PAGE_REDESIGN_VARIANT: 'companyPageRedesignVariant',
  EW_EXP: 'ew_exp',
};
export const company_mapping: any = {
  'act,_inc.': 'act-american_college_testing',
  'agren,_inc.': 'land_olakes',
  'alliancetek_inc.': 'alliancetek_inc',
  'anthem,_inc.': 'elevance_health',
  'ascent_processing,_inc.': 'ascent_processing_inc',
  'bit9_+_carbon_black': 'vmware',
  'daysplan,_inc.': 'daysplan_inc',
  'footsteps2brilliance,_inc.': 'footsteps2brilliance_inc',
  'hitachi,_ltd.': 'hitachi',
  'levi_strauss_&_co.': 'levi_strauss_co',
  'object_technology_solutions,_inc.': 'object_technology_solutions_inc',
  'perception_strategies,_inc.': 'perception_strategies_inc',
  'pharmaceutical_product_development_inc.':
    'thermo_fisher_scientific-ppd-pharmaceutical_product_development',
  'snap_inc.': 'snap_inc',
  'talent_inc.': 'talent_inc',
  'thrive_technologies_inc.': 'thrive_technologies_inc',
};
export const ApiBaseUrl: Record<string, string> = {
  BASE_URL_EB: process.env.NEXT_PUBLIC_EB_URL || '',
  BASE_URL_RJ: process.env.NEXT_PUBLIC_JOBS_URL_RJ || '',
  BASE_URL_CORE: process.env.NEXT_PUBLIC_FJ_URL || '',
};

export const ApiBaseUrlServer: Record<string, string> = {
  BASE_URL_EB: process.env.NEXT_PUBLIC_EB_URL_SERVER || '',
  BASE_URL_RJ: process.env.NEXT_PUBLIC_JOBS_URL_RJ_SERVER || '',
  BASE_URL_CORE: process.env.NEXT_PUBLIC_FJ_URL_SERVER || '',
};

export const ApiBaseUrlsWindow: Record<string, string> = {
  BASE_URL_EB: process.env.NEXT_PUBLIC_EB_URL || '',
  BASE_URL_EJ: process.env.NEXT_PUBLIC_JOBS_URL_RJ || '',
  BASE_URL_FJ: process.env.NEXT_PUBLIC_FJ_URL || '',
};

export const ApiBaseUrlsServer: Record<string, string> = {
  BASE_URL_EB: process.env.NEXT_PUBLIC_EB_URL_SERVER || '',
  BASE_URL_EJ: process.env.NEXT_PUBLIC_JOBS_URL_RJ_SERVER || '',
  BASE_URL_FJ: process.env.NEXT_PUBLIC_FJ_URL_SERVER || '',
};

export const WIDGETS_NAME: {
  NEWS: string;
  SUCCESS_STORIES: string;
  PROMO_BANNER: string;
  NEWS_LETTER: string;
  CURRENTLY_HIRING: string;
  RELATED_ARTICLES: string;
  FOLLOWED_COMPANIES: string;
  COMPANY_HIRING: string;
  RELATED_COMPANY_SEARCH: string;
  MPR: string;
  SIMILAR_JOBS: string;
  UPCOMING_EVENT: string;
  CURRENTLY_HIRING_TYPE: string;
} = {
  NEWS: 'news',
  SUCCESS_STORIES: 'success-stories',
  PROMO_BANNER: 'promo-banner',
  NEWS_LETTER: 'news-letter',
  CURRENTLY_HIRING: 'currently-hiring',
  RELATED_ARTICLES: 'related-articles',
  FOLLOWED_COMPANIES: 'followed-companies',
  COMPANY_HIRING: 'company-hiring',
  RELATED_COMPANY_SEARCH: 'related-company-search',
  MPR: 'my-perfect-resume',
  SIMILAR_JOBS: 'similar-jobs',
  UPCOMING_EVENT: 'upcoming-event',
  CURRENTLY_HIRING_TYPE: 'currently-hiring-type',
};

export const enum ApiVersion {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}

export const TESTIMONIALS = [
  {
    quote: `Thank you, ${isPortalContent}, for assisting me in finding this amazing employment opportunity!`,
    by: 'Rebecca T., Brigham City, UT',
    detail:
      'Inbound Customer Service Representative at Discover Financial Services',
    date: 'Mar 13, 2023',
  },
  {
    quote: `I was out of a job for a while and wanted to break into the work-from-home job market, and ${isPortalContent} worked wonders!`,
    by: 'Christine A., Middletown, MD',
    detail: 'Call Center Agent at Working Solutions',
    date: 'Mar 8, 2023',
  },
  {
    quote: `Thank you, ${isPortalContent}, for assisting me in finding this amazing employment opportunity!`,
    by: 'Rebecca T., Brigham City, UT',
    detail:
      'Inbound Customer Service Representative at Discover Financial Services',
    date: 'Mar 13, 2023',
  },
  {
    quote: `I was out of a job for a while and wanted to break into the work-from-home job market, and ${isPortalContent} worked wonders!`,
    by: 'Christine A., Middletown, MD',
    detail: 'Call Center Agent at Working Solutions',
    date: 'Mar 8, 2023',
  },
  {
    quote: `Thank you, ${isPortalContent}, for assisting me in finding this amazing employment opportunity!`,
    by: 'Rebecca T., Brigham City, UT',
    detail:
      'Inbound Customer Service Representative at Discover Financial Services',
    date: 'Mar 13, 2023',
  },
  {
    quote: `I was out of a job for a while and wanted to break into the work-from-home job market, and ${isPortalContent} worked wonders!`,
    by: 'Christine A., Middletown, MD',
    detail: 'Call Center Agent at Working Solutions',
    date: 'Mar 8, 2023',
  },
  {
    quote: `Thank you, ${isPortalContent}, for assisting me in finding this amazing employment opportunity!`,
    by: 'Rebecca T., Brigham City, UT',
    detail:
      'Inbound Customer Service Representative at Discover Financial Services',
    date: 'Mar 13, 2023',
  },
];

export const Revalidate = Number(process.env.REVALIDATE) || 7200;

export const relatedArticles = [
  {
    name: 'Top 30 Companies That Hire for Work-From-Anywhere Jobs',
    slug: '/blog/post/top-companies-work-from-anywhere-remote-jobs',
    imgSrc:
      'https://fjwp.s3.amazonaws.com/blog/wp-content/uploads/2023/04/18072055/Top-30-Companies-That-Hire-for-Work-From-Anywhere-Jobs2.jpg',
  },
  {
    name: ' 10 Remote, Work-from-Home Jobs With No Experience Needed',
    slug: '/blog/post/how-to-make-money-from-home-no-work-experience',
    imgSrc:
      'https://fjwp.s3.amazonaws.com/blog/wp-content/uploads/2021/02/25083908/How-to-Find-Online-Jobs-Work-From-Home-Jobs-That-Require-Little-or-No-Work-Experience.jpg',
  },
];

export const followedCompnies = [
  {
    imgSrc: 'https://fjdsas.s3.amazonaws.com/wa4ex6h5xiil7kn4zcxeiadn4pml',
    slug: '/remote-jobs/company/lumen_technologies',
    title: 'Lumen Technologies',
  },
  {
    imgSrc: 'https://fjdsas.s3.amazonaws.com/8qd0okdpbprz7ytcf1fsnisoux1j',
    slug: '/remote-jobs/company/faegre_drinker',
    title: 'Faegre Drinker',
  },
];

export const defaultCompanyLogo =
  '/images/icons/streamline-thin/streamline-icon-real-estate-building-person.svg';

export const commonRegex = {
  htmlTags: /<\/?[^>]*>/g,
};

export const PreSelectedFilterPage = {
  'full-time': {
    title: 'Full Time Jobs Online',
    scheduleType: 'Full-Time',
    selecteFilter: {
      queryVal: 'Full-Time',
      jobschedules: 'Full-Time',
    },
  },
  'part-time': {
    title: 'Part Time Jobs Online',
    scheduleType: 'Part-Time',
    selecteFilter: {
      queryVal: 'Part-Time',
      jobschedules: 'Part-Time',
    },
  },
  freelance: {
    title: 'Freelance Jobs Online',
    scheduleType: 'Freelance',
    selecteFilter: {
      queryVal: 'freelance',
      jobtypes: 'Freelance',
    },
  },
  temp: {
    title: 'Temporary Jobs',
    scheduleType: 'Temporary',
    selecteFilter: {
      queryVal: 'temporary',
      jobtypes: 'Temporary',
    },
  },
  'entry-level': {
    title: 'Entry Level Jobs Online',
    scheduleType: 'Entry-Level',
    selecteFilter: {
      queryVal: 'Entry-Level',
      careerlevel: 'Entry-Level',
    },
  },
};

export const EducationLevelText = {
  noEduSpecified: 'No Education Specified',
  sorryTxt:
    "We're sorry, the employer did not include education information for this job.",
};

export const CATEGORY_ICON_SLUG: any = {
  'account-management': {
    icon: 'streamline-icon-accounting-calculator.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-client-services': {
    icon: 'streamline-icon-accounting-calculator.svg',
    isSubCategory: true,
    parentCategory: { name: 'Account Management', slug: 'account-management' },
  },
  accounting: {
    icon: 'streamline-icon-saving-bag-increase.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  bookkeeping: {
    icon: 'streamline-icon-saving-bag-increase.svg',
    isSubCategory: true,
    parentCategory: { name: 'Accounting & Finance', slug: 'accounting' },
  },
  auditing: {
    icon: 'streamline-icon-saving-bag-increase.svg',
    isSubCategory: true,
    parentCategory: { name: 'Accounting & Finance', slug: 'accounting' },
  },
  banking: {
    icon: 'streamline-icon-saving-bag-increase.svg',
    isSubCategory: true,
    parentCategory: { name: 'Accounting & Finance', slug: 'accounting' },
  },
  'administration-support-services': {
    icon: 'streamline-icon-administrative.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-collections': {
    icon: 'streamline-icon-administrative.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Administrative',
      slug: 'administration-support-services',
    },
  },
  'online-virtual-admin': {
    icon: 'streamline-icon-administrative.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Administrative',
      slug: 'administration-support-services',
    },
  },
  'online-appointment-setting': {
    icon: 'streamline-icon-administrative.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Administrative',
      slug: 'administration-support-services',
    },
  },
  'advertising-pr': {
    icon: 'streamline-icon-messages-people-user-star.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-advertising': {
    icon: 'streamline-icon-messages-people-user-star.svg',
    isSubCategory: true,
    parentCategory: { name: 'Advertising & PR', slug: 'advertising-pr' },
  },
  'animals-wildlife': {
    icon: 'streamline-icon-pets-paw-heart.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'art-products-services': {
    icon: 'streamline-icon-color-tube-painting.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'photography-art': {
    icon: 'streamline-icon-color-tube-painting.svg',
    isSubCategory: true,
    parentCategory: { name: 'Art & Creative', slug: 'art-products-services' },
  },
  'online-music': {
    icon: 'streamline-icon-color-tube-painting.svg',
    isSubCategory: true,
    parentCategory: { name: 'Art & Creative', slug: 'art-products-services' },
  },
  theater: {
    icon: 'streamline-icon-color-tube-painting.svg',
    isSubCategory: true,
    parentCategory: { name: 'Art & Creative', slug: 'art-products-services' },
  },
  bilingual: {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-spanish': {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  'online-french': {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  chinese: {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  german: {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  japanese: {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  portuguese: {
    icon: 'streamline-icon-passport.svg',
    isSubCategory: true,
    parentCategory: { name: 'Bilingual', slug: 'bilingual' },
  },
  'business-development': {
    icon: 'streamline-icon-performance-increase-mail.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'call-center': {
    icon: 'streamline-icon-phone-actions-ringing.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-inbound-call': {
    icon: 'streamline-icon-phone-actions-ringing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Call Center', slug: 'call-center' },
  },
  'outbound-call': {
    icon: 'streamline-icon-phone-actions-ringing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Call Center', slug: 'call-center' },
  },
  communications: {
    icon: 'streamline-icon-conversation-browser.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'computer-it': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-technical-support': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  'online-sql': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  'computer-security': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  'database-administration': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  'system-administration': {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  networking: {
    icon: 'streamline-icon-laptop-sync.svg',
    isSubCategory: true,
    parentCategory: { name: 'Computer & IT', slug: 'computer-it' },
  },
  'consulting-coaching': {
    icon: 'streamline-icon-team-share-idea.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'it-consulting': {
    icon: 'streamline-icon-team-share-idea.svg',
    isSubCategory: true,
    parentCategory: { name: 'Consulting', slug: 'consulting-coaching' },
  },
  'healthcare-consultant': {
    icon: 'streamline-icon-team-share-idea.svg',
    isSubCategory: true,
    parentCategory: { name: 'Consulting', slug: 'consulting-coaching' },
  },
  'education-consultant': {
    icon: 'streamline-icon-team-share-idea.svg',
    isSubCategory: true,
    parentCategory: { name: 'Consulting', slug: 'consulting-coaching' },
  },
  'business-consultant': {
    icon: 'streamline-icon-team-share-idea.svg',
    isSubCategory: true,
    parentCategory: { name: 'Consulting', slug: 'consulting-coaching' },
  },
  'customer-service-call-center': {
    icon: 'streamline-icon-conversation-smile-type.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'data-entry': {
    icon: 'streamline-icon-keyboard-wireless.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  editing: {
    icon: 'streamline-icon-editing.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-proofreading': {
    icon: 'streamline-icon-editing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Editing', slug: 'editing' },
  },
  'education-training': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-tutoring': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Education & Training',
      slug: 'education-training',
    },
  },
  'online-teaching': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Education & Training',
      slug: 'education-training',
    },
  },
  'online-k12': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Education & Training',
      slug: 'education-training',
    },
  },
  'online-college-university': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Education & Training',
      slug: 'education-training',
    },
  },
  'education-online-teaching': {
    icon: 'streamline-icon-e-learning-monitor.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Education & Training',
      slug: 'education-training',
    },
  },
  engineering: {
    icon: 'streamline-icon-tools-wench-screwdriver.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'entertainment-media': {
    icon: 'streamline-icon-show-theater-masks.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  video: {
    icon: 'streamline-icon-show-theater-masks.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Entertainment & Media',
      slug: 'entertainment-media',
    },
  },
  film: {
    icon: 'streamline-icon-show-theater-masks.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Entertainment & Media',
      slug: 'entertainment-media',
    },
  },
  gaming: {
    icon: 'streamline-icon-show-theater-masks.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Entertainment & Media',
      slug: 'entertainment-media',
    },
  },
  environmental: {
    icon: 'streamline-icon-recycling-refresh.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'event-planning-wedding': {
    icon: 'streamline-icon-calendar-edit.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'fashion-beauty': {
    icon: 'streamline-icon-dating-lipstick.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'food-hospitality': {
    icon: 'streamline-icon-chef-gear-hat.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'government-politics': {
    icon: 'streamline-icon-official-building.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'federal-government': {
    icon: 'streamline-icon-official-building.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Government & Politics',
      slug: 'government-politics',
    },
  },
  military: {
    icon: 'streamline-icon-official-building.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Government & Politics',
      slug: 'government-politics',
    },
  },
  'state-local-government': {
    icon: 'streamline-icon-official-building.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Government & Politics',
      slug: 'government-politics',
    },
  },
  'graphic-design': {
    icon: 'streamline-icon-vectors-pen-add.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'digital-design': {
    icon: 'streamline-icon-vectors-pen-add.svg',
    isSubCategory: true,
    parentCategory: { name: 'Graphic Design', slug: 'graphic-design' },
  },
  'interactive-design': {
    icon: 'streamline-icon-vectors-pen-add.svg',
    isSubCategory: true,
    parentCategory: { name: 'Graphic Design', slug: 'graphic-design' },
  },
  'print-design': {
    icon: 'streamline-icon-vectors-pen-add.svg',
    isSubCategory: true,
    parentCategory: { name: 'Graphic Design', slug: 'graphic-design' },
  },
  'visual-design': {
    icon: 'streamline-icon-vectors-pen-add.svg',
    isSubCategory: true,
    parentCategory: { name: 'Graphic Design', slug: 'graphic-design' },
  },
  'human-resources-recruiting': {
    icon: 'streamline-icon-job-choose-cadidate.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'human-services': {
    icon: 'streamline-icon-messages-people-user-bubble-circle.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'social-work': {
    icon: 'streamline-icon-messages-people-user-bubble-circle.svg',
    isSubCategory: true,
    parentCategory: { name: 'Human Services', slug: 'human-services' },
  },
  community: {
    icon: 'streamline-icon-messages-people-user-bubble-circle.svg',
    isSubCategory: true,
    parentCategory: { name: 'Human Services', slug: 'human-services' },
  },
  insurance: {
    icon: 'streamline-icon-car-insurance.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'insurance-underwriting': {
    icon: 'streamline-icon-car-insurance.svg',
    isSubCategory: true,
    parentCategory: { name: 'Insurance', slug: 'insurance' },
  },
  'insurance-claims': {
    icon: 'streamline-icon-car-insurance.svg',
    isSubCategory: true,
    parentCategory: { name: 'Insurance', slug: 'insurance' },
  },
  'risk-management': {
    icon: 'streamline-icon-car-insurance.svg',
    isSubCategory: true,
    parentCategory: { name: 'Insurance', slug: 'insurance' },
  },
  international: {
    icon: 'streamline-icon-earth-refresh.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'internet-ecommerce': {
    icon: 'streamline-icon-internet-and-commerce.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-seo-sem': {
    icon: 'streamline-icon-internet-and-commerce.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Internet & Ecommerce',
      slug: 'internet-ecommerce',
    },
  },
  'online-social-media': {
    icon: 'streamline-icon-internet-and-commerce.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Internet & Ecommerce',
      slug: 'internet-ecommerce',
    },
  },
  legal: {
    icon: 'streamline-icon-legal.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  manufacturing: {
    icon: 'streamline-icon-manufacturing.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  marketing: {
    icon: 'streamline-icon-marketing.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-marketing': {
    icon: 'streamline-icon-manufacturing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Marketing', slug: 'marketing' },
  },
  'math-economics': {
    icon: 'streamline-icon-performance-graph-calculator.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  statistics: {
    icon: 'streamline-icon-performance-graph-calculator.svg',
    isSubCategory: true,
    parentCategory: { name: 'Math & Economics', slug: 'math-economics' },
  },
  'healthcare-medical': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-nursing': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'online-pharmaceutical': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'case-management': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  therapy: {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-coding': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  dental: {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  nutrition: {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  psychiatry: {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'doctor-practitioner': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'health-administration': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-billing': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-imaging': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-lab-technology': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-research': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'medical-pediatric': {
    icon: 'streamline-icon-hospital-first-aid.svg',
    isSubCategory: true,
    parentCategory: { name: 'Medical & Health', slug: 'healthcare-medical' },
  },
  'real-estate': {
    icon: 'streamline-icon-mortgage-and-real-estate.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'news-journalism': {
    icon: 'streamline-icon-news-and-journalism.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  reporter: {
    icon: 'streamline-icon-news-and-journalism.svg',
    isSubCategory: true,
    parentCategory: { name: 'News & Journalism', slug: 'news-journalism' },
  },
  publishing: {
    icon: 'streamline-icon-news-and-journalism.svg',
    isSubCategory: true,
    parentCategory: { name: 'News & Journalism', slug: 'news-journalism' },
  },
  'nonprofit-philanthropy': {
    icon: 'streamline-icon-love-it-hand-give.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-fundraising': {
    icon: 'streamline-icon-love-it-hand-give.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Nonprofit & Philanthropy',
      slug: 'nonprofit-philanthropy',
    },
  },
  operations: {
    icon: 'streamline-icon-task-list-settings.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  product: {
    icon: 'streamline-icon-monitor-touch.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'project-management': {
    icon: 'streamline-icon-time-clock-file-setting.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'research-development': {
    icon: 'streamline-icon-read-glasses.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  analyst: {
    icon: 'streamline-icon-read-glasses.svg',
    isSubCategory: true,
    parentCategory: { name: 'Research', slug: 'research-development' },
  },
  library: {
    icon: 'streamline-icon-read-glasses.svg',
    isSubCategory: true,
    parentCategory: { name: 'Research', slug: 'research-development' },
  },
  'consumer-products': {
    icon: 'streamline-icon-shopping-cart-star.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'sales-business-development': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-business-to-business': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'online-business-to-consumer': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'online-medical-sales': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'online-sales-representative': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'insurance-sales': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'software-sales': {
    icon: 'streamline-icon-messages-people-user-dollar.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sales', slug: 'sales-business-development' },
  },
  'pharmaceutical-science': {
    icon: 'streamline-icon-lab-tube-experiment.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  chemistry: {
    icon: 'streamline-icon-lab-tube-experiment.svg',
    isSubCategory: true,
    parentCategory: { name: 'Science', slug: 'pharmaceutical-science' },
  },
  'web-software-development-programming': {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-java': {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  'online-php': {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  asp: {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  'ruby-on-rails': {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  python: {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  'qa-testing': {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  ios: {
    icon: 'streamline-icon-programming-browser.svg',
    isSubCategory: true,
    parentCategory: {
      name: 'Software Development',
      slug: 'web-software-development-programming',
    },
  },
  'sports-fitness': {
    icon: 'streamline-icon-fitness-weights.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  coaching: {
    icon: 'streamline-icon-fitness-weights.svg',
    isSubCategory: true,
    parentCategory: { name: 'Sports & Fitness', slug: 'sports-fitness' },
  },
  telemarketing: {
    icon: 'streamline-icon-smart-house-call.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  transcription: {
    icon: 'streamline-icon-ecology-globe-message.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'translation-travel': {
    icon: 'streamline-icon-audio-book-record-microphone-a.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'travel-hospitality': {
    icon: 'streamline-icon-airplane-swoosh.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'web-design': {
    icon: 'streamline-icon-vectors-square-link.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'writing-editing-journalism': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
  'online-blogging': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Writing', slug: 'writing-editing-journalism' },
  },
  'online-copywriting': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Writing', slug: 'writing-editing-journalism' },
  },
  'online-grant-writing': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Writing', slug: 'writing-editing-journalism' },
  },
  'online-content': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Writing', slug: 'writing-editing-journalism' },
  },
  'technical-writing': {
    icon: 'streamline-icon-writing.svg',
    isSubCategory: true,
    parentCategory: { name: 'Writing', slug: 'writing-editing-journalism' },
  },
  'youth-children': {
    icon: 'streamline-icon-family-child-teeter.svg',
    isSubCategory: false,
    parentCategory: { name: null, slug: null },
  },
};

export const AdvanceSearchFieldNames = {
  searchkeyword: 'searchkeyword',
  remoteoptions: 'remoteoptions',
  usLocation: 'USLocation',
  internationalLocation: 'interNationalLocation',
  jobtypes: 'jobtypes',
  jobschedules: 'jobschedules',
  careerlevel: 'careerlevel',
  educations: 'educations',
  accolades: 'accolades',
  categories: 'categories',
  travels: 'travels',
  excludeWords: 'excludeWords',
  anywhereInUS: 'anywhereInUS',
  anywhereInWorld: 'anywhereInWorld',
};

export const US_GLOBAL_LOCATION = {
  US_LOC: [
    'United States',
    'US',
    'USA',
    'U.S.',
    'U.S.A.',
    'U.S',
    'US National',
  ],
  GLOBAL_LOC: ['Anywhere', 'Global', 'World', 'Work from Anywhere'],
};

export const LOCATION_LOOKUP_COUNT = 15;

export const DISALLOW_MULTISELECT = ['careerlevel'];

export const LOCATION_CHECK_BOX_VALUE = {
  US: 'Work from Anywhere in US',
  WORLD: 'Work from Anywhere',
};

export const USER_TYPE = {
  Employer: 'Employer',
  Partner: 'Econ',
};

export const HIDDEN_COUNTRY_NAMES = [
  // Enter in lowercase
  'united states',
];

export const picIcon: any = {
  'Computer Skills':
    '/images/icons/streamline-thin/streamline-icon-monitor-touch.svg',
  Databases: '/images/icons/streamline-thin/streamline-icon-database-flash.svg',
  'Engineering  Skills':
    '/images/icons/streamline-thin/streamline-icon-tools-wench-screwdriver.svg',
  Engineering:
    '/images/icons/streamline-thin/streamline-icon-tools-wench-screwdriver.svg',
  'English Language':
    '/images/icons/streamline-thin/streamline-icon-messages-bubble-square-menu.svg',
  'Finance and Accounting':
    '/images/icons/streamline-thin/streamline-icon-accounting-calculator-2.svg',
  'Finance & Accounting':
    '/images/icons/streamline-thin/streamline-icon-accounting-calculator-2.svg',
  'General Programming':
    '/images/icons/streamline-thin/streamline-icon-programming-hold-code.svg',
  'Programming Languages':
    '/images/icons/streamline-thin/streamline-icon-programming-team-chat.svg',
  'Programming Tools':
    '/images/icons/streamline-thin/streamline-icon-programming-hold-code.svg',
  'General Management':
    '/images/icons/streamline-thin/streamline-icon-human-resources-hierarchy-1.svg',
  'Graphics Designing':
    '/images/icons/streamline-thin/streamline-icon-color-tube-painting.svg',
  'Graphic Design':
    '/images/icons/streamline-thin/streamline-icon-color-tube-painting.svg',
  Healthcare:
    '/images/icons/streamline-thin/streamline-icon-hospital-first-aid.svg',
  'Medical & Health':
    '/images/icons/streamline-thin/streamline-icon-hospital-first-aid.svg',
  'Intelligence and Aptitude':
    '/images/icons/streamline-thin/streamline-icon-study-maths-brain.svg',
  'Cognitive Skills':
    '/images/icons/streamline-thin/streamline-icon-study-maths-brain.svg',
  'International Languages':
    '/images/icons/streamline-thin/streamline-icon-earth-refresh.svg',
  'Internet Concepts':
    '/images/icons/streamline-thin/streamline-icon-internet-and-commerce.svg',
  'Internet Programming':
    '/images/icons/streamline-thin/streamline-icon-programming-browser.svg',
  'Medical Transcription and Medical Billing':
    '/images/icons/streamline-thin/streamline-icon-medical-file.svg',
  'Miscellaneous Certifications':
    '/images/icons/streamline-thin/streamline-icon-certified-ribbon.svg',
  'Mobile Technologies':
    '/images/icons/streamline-thin/streamline-icon-phone-action-at.svg',
  Networking: '/images/icons/streamline-thin/streamline-icon-server-add.svg',
  'Office Skills':
    '/images/icons/streamline-thin/streamline-icon-keyboard-wireless.svg',
  'Operating Systems':
    '/images/icons/streamline-thin/streamline-icon-monitor-download.svg',
  'Personality Tests':
    '/images/icons/streamline-thin/streamline-icon-brain-head-1.svg',
  'Sales and Marketing':
    '/images/icons/streamline-thin/streamline-icon-marketing.svg',
  Sales:
    '/images/icons/streamline-thin/streamline-icon-real-estate-market-house-increase.svg',
  'Software Testing':
    '/images/icons/streamline-thin/streamline-icon-app-window-settings.svg',
  'Translation Skills':
    '/images/icons/streamline-thin/streamline-icon-ecology-globe-message.svg',
  'Web Designing':
    '/images/icons/streamline-thin/streamline-icon-vectors-pen-add.svg',
  'Writing and Publishing':
    '/images/icons/streamline-thin/streamline-icon-writing.svg',
  'Writing & Editing':
    '/images/icons/streamline-thin/streamline-icon-writing.svg',
  'Popular Skills Tests':
    '/images/icons/streamline-thin/streamline-icon-global-warming-globe-fire.svg',
  'Data Entry & Analysis':
    '/images/icons/streamline-thin/streamline-icon-keyboard-wireless.svg',
  'Call Center':
    '/images/icons/streamline-thin/streamline-icon-phone-actions-ringing.svg',
  'System Administration':
    '/images/icons/streamline-thin/streamline-icon-laptop-settings.svg',
  'Management Skills':
    '/images/icons/streamline-thin/streamline-icon-human-resources-hierarchy-1.svg',
  'Job Search':
    '/images/icons/streamline-thin/streamline-icon-laptop-search.svg',
  'Remote Work Skills':
    '/images/icons/streamline-thin/streamline-icon-laptop-approve.svg',
  'Human Resources':
    '/images/icons/streamline-thin/streamline-icon-job-choose-cadidate.svg',
  Legal: '/images/icons/streamline-thin/streamline-icon-legal-hammer.svg',
  Insurance: '/images/icons/streamline-thin/streamline-icon-car-insurance.svg',
  'Teaching & Education':
    '/images/icons/streamline-thin/streamline-icon-e-learning-monitor.svg',
  Marketing:
    '/images/icons/streamline-thin/streamline-icon-advertising-megaphone.svg',
  'General Work Skills':
    '/images/icons/streamline-thin/streamline-icon-tool-box-2.svg',
  'Environmental Health':
    '/images/icons/streamline-thin/streamline-icon-recycling-refresh.svg',
  'Customer Service':
    '/images/icons/streamline-thin/streamline-icon-conversation-smile-type.svg',
};

export const SECTION_TYPE_CD = {
  jobSearchBasics: 'JSRB',
};

export const FIELD_TYPE_CD = {
  jobCategory: 'JBCT',
};

export const SAVED_SEARCH_TYPE = {
  followedCompany: 'followedComp',
  savedSearch: 'savedSearch',
};

export const ECOM_WIDGET_URLS = ['signup', 'login', 'registration/msignup'];

export const Debuger_Script_URLS = [
  '/remote-jobs/world/Anywhere',
  '/remote-jobs/world/United-Kingdom',
  '/remote-jobs/world/India',
  '/remote-jobs/world/Mexico',
  '/remote-jobs/world/Japan',
  '/remote-jobs/company/whatsapp',
];

export enum SAVED_SEARCH_BUTTON_STATE {
  save_search = 'SAVE_SEARCH',
  saved = 'Saved',
  save_changes = 'SAVE_CHANGES',
  hide = 'HIDE',
}

export enum EXIT_POP_UP_EVENT_LABEL {
  location = 'Location Page',
  popular_keyword = 'popular keyword page',
  top_searched = 'Top Searched Page',
  category_page = 'Category Page',
  about = 'about',
  benefits = 'benefits',
  pricing = 'pricing',
  company = 'remote-jobs/company',
  registration_page = 'Registration Page',
  tour = 'tour',
  public_jobs = 'publicjobs',
}
export const filterDropOrder: any = {
  Remote: {
    '100% Remote Work': '0',
    'Hybrid Remote Work': '0',
    'Option for Remote Work': '0',
    'No Remote Work': '0',
  },
  'Job Type': { Employee: '0', Freelance: '0', Temporary: '0' },
  'Career Level': {
    'Entry-Level': '0',
    Experienced: '0',
    Manager: '0',
    'Senior Level Manager': '0',
  },
  Schedule: {
    'Full-Time': '0',
    'Part-Time': '0',
    'Flexible Schedule': '0',
    'Alternative Schedule': '0',
  },
  Education: {
    'No Education Specified': '0',
    "Associate's Degree or Some College": '0',
    "Bachelor's/Undergraduate Degree": '0',
    "Master's Degree": '0',
    'Doctorate Degree': '0',
  },
  Travel: {
    No: '0',
    Yes: '0',
    'No specification': '0',
  },
  Category: {},
};

export const API_NAMES = {
  COUNTRY_LIST_EB: 'countryListEB',
  NEWS_WIDGET: 'newsWidget',
  SUCCESS_STORY_WIDGET: 'successStoryWidget',
  HIRING_COUNT: 'hiringCount',
  SAVE_JOB_ID: 'saveJobId',
  JOB_CATEGORIES: 'jobCategories',
};

export const TIMEOUT_DURATION =
  Number(process.env.NEXT_PUBLIC_REQ_TIMEOUT) || 10000;

export const RegTestimonials = [
  {
    comment: `I joined ${isPortalContent} skeptical that I would find a remote job because I have large employment gaps from being a stay-at-home mom.<strong> After the first week, I had three interviews and landed two jobs.</strong>`,
    author: '-Ashley P.,  <strong>Hired at ModSquad</strong>',
  },
  {
    comment: `I only wish I had heard of ${isPortalContent} sooner.<strong> Within a month of joining, I was interviewing for a new job that felt like a Cinderella fit for my skill set.</strong> The best part is that it&apos;s 100% remote, so no need to worry about the morning commute.`,
    author: '-Lorraine B., <strong>Hired at Rainforest Trust</strong>',
  },
  {
    comment: `Remote work is getting harder to find, and ${isPortalContent} served me better than other sites combined.<strong> Signing up for ${isPortalContent} was the best job hunt decision I&apos;ve ever made.</strong>`,
    author: '-Tom R., <strong>Hired at Beyond Finance</strong>',
  },
];

export const Top_Qualities_Remote_Sites = [
  {
    headcont: 'Highest Quality Listings',
    content:
      'Only legit jobs. No ads, scams, or junk to sift through. Our team spends 200+ hours/day verifying every job.',
  },
  {
    headcont: 'Unlimited Job Search Resources',
    content:
      'Full access to all features including unlimited jobs, articles, skills tests, and webinars to help you with your remote job search.',
  },
  {
    headcont: 'Save Time',
    content:
      'Go straight from job listings to applications. No more hopping from one job board to the next.',
  },
];

export const jobFeedSlugs = ['gjw', 'partner'];

export const LocalStorageKeys: Record<string, string> = {
  TotalJobsCount: 'TotalJobsCount',
};

export const locationQueryMapping: Record<string, string> = {
  'st-paul': 'St.-Paul',
  'st-petersburg': 'St.-Petersburg',
};

export const unlockBannerImages = [
  'unlock-banner-1.png',
  'unlock-banner-2.jpg',
  'unlock-banner-3.png',
];

export const TWENTY_FOUR_HOUR_CACHE = 24 * 60 * 60 * 1000;

export const STATE_CATEGORIES = [
  ['accounting', 'Accounting'],
  ['administrative-assistant', 'Administrative Assistant'],
  ['analyst', 'Analyst'],
  ['bookkeeping', 'Bookkeeping'],
  ['call-center', 'Call Center'],
  ['chat-support', 'Chat Support'],
  ['communications', 'Communications'],
  ['compliance', 'Compliance'],
  ['consulting', 'Consulting'],
  ['copywriting', 'Copywriting'],
  ['creative', 'Creative'],
  ['criminal-justice', 'Criminal Justice'],
  ['customer-service', 'Customer Service'],
  ['cybersecurity', 'Cybersecurity'],
  ['data-analyst', 'Data Analyst'],
  ['data-entry', 'Data Entry'],
  ['data-science', 'Data Science'],
  ['design', 'Design'],
  ['developer', 'Developer'],
  ['editing', 'Editing'],
  ['education', 'Education'],
  ['environmental', 'Environmental'],
  ['finance', 'Finance'],
  ['freelance', 'Freelance'],
  ['graphic-design', 'Graphic Design'],
  ['healthcare', 'Healthcare'],
  ['hospitality', 'Hospitality'],
  ['human-resources', 'Human Resources'],
  ['it', 'IT'],
  ['legal', 'Legal'],
  ['life-coach', 'Life Coach'],
  ['live-chat', 'Live Chat'],
  ['marketing', 'Marketing'],
  ['medical', 'Medical'],
  ['nonprofit', 'Nonprofit'],
  ['nursing', 'Nursing'],
  ['part-time', 'Part-Time'],
  ['procurement', 'Procurement'],
  ['product-manager', 'Product Manager'],
  ['project-management', 'Project Management'],
  ['proofreading', 'Proofreading'],
  ['public-health', 'Public Health'],
  ['qa', 'QA'],
  ['recruiter', 'Recruiter'],
  ['rn', 'RN'],
  ['sales', 'Sales'],
  ['social-media', 'Social Media'],
  ['software-engineering', 'Software Engineering'],
  ['teaching', 'Teaching'],
  ['training', 'Training'],
  ['transcription', 'Transcription'],
  ['translation', 'Translation'],
  ['travel-agent', 'Travel Agent'],
  ['tutoring', 'Tutoring'],
  ['typing', 'Typing'],
  ['ui-and-ux', 'UI & UX'],
  ['video-editing', 'Video Editing'],
  ['virtual-assistant', 'Virtual Assistant'],
  ['writing', 'Writing'],
];

export const countryTitlesArr = {
  'Other International Countries': 'All International',
};

export const countrySubTitlesArr = {
  'Other International Countries': 'All Flexible and Remote International Jobs',
};

export const metaTitleArr = {
  'Other International Countries': 'International',
};

export const metaDescriptionArr = {
  'Other International Countries': `Find remote, work from home international and overseas jobs in over 240 countries. Discover a better job search with ${isPortalContent} today.`,
};

export const readValueUsingKey = (titleArr: any, category: string) => {
  if (category in titleArr) {
    return titleArr[category];
  } else {
    return null;
  }
};

export const remoteJobsCategorySlugs = [
  {
    name: 'Accounting',
    slug: 'accounting',
    id: 1,
    heading: 'Remote Accounting Jobs',
    headingLink: '/remote-jobs/accounting',
    seeAllTitle: 'See All Remote Accounting Jobs',
    seeAllLink: '/remote-jobs/accounting',
  },
  {
    name: 'Bookkeeping',
    slug: 'bookkeeping',
    id: 2,
    heading: 'Remote Bookkeeping Jobs',
    headingLink: '/remote-jobs/bookkeeping',
    seeAllTitle: 'See All Remote Bookkeeping Jobs',
    seeAllLink: '/remote-jobs/bookkeeping',
  },
  {
    name: 'Customer Service',
    slug: 'customer-service',
    id: 3,
    heading: 'Remote Customer Service Jobs',
    headingLink: '/remote-jobs/customer-service',
    seeAllTitle: 'See All Remote Customer Service Jobs',
    seeAllLink: '/remote-jobs/customer-service',
  },
  {
    name: 'Data Entry Jobs',
    slug: 'online-data-entry',
    id: 4,
    heading: 'Remote Data Entry Jobs',
    headingLink: '/remote-jobs/online-data-entry',
    seeAllTitle: 'See All Remote Data Entry Jobs',
    seeAllLink: '/remote-jobs/online-data-entry',
  },
  {
    name: 'Editing',
    slug: 'online-editing',
    id: 5,
    heading: 'Remote Editing Jobs',
    headingLink: '/remote-jobs/online-editing',
    seeAllTitle: 'See All Remote Editing Jobs',
    seeAllLink: '/remote-jobs/online-editing',
  },
  {
    name: 'Healthcare',
    slug: 'healthcare',
    id: 6,
    heading: 'Remote Healthcare Jobs',
    headingLink: '/remote-jobs/healthcare',
    seeAllTitle: 'See All Remote Healthcare Jobs',
    seeAllLink: '/remote-jobs/healthcare',
  },
  {
    name: 'Nursing',
    slug: 'nursing',
    id: 7,
    heading: 'Remote Nursing Jobs',
    headingLink: '/remote-jobs/nursing',
    seeAllTitle: 'See All Remote Nursing Jobs',
    seeAllLink: '/remote-jobs/nursing',
  },
  {
    name: 'Legal',
    slug: 'legal',
    id: 8,
    heading: 'Remote Legal Jobs',
    headingLink: '/remote-jobs/legal',
    seeAllTitle: 'See All Remote Legal Jobs',
    seeAllLink: '/remote-jobs/legal',
  },
  {
    name: 'Marketing',
    slug: 'marketing',
    id: 9,
    heading: 'Remote Marketing Jobs',
    headingLink: '/remote-jobs/marketing',
    seeAllTitle: 'See All Remote Marketing Jobs',
    seeAllLink: '/remote-jobs/marketing',
  },
  {
    name: 'Social Media Jobs',
    slug: 'social-media',
    id: 10,
    heading: 'Remote Social Media Jobs',
    headingLink: '/remote-jobs/social-media',
    seeAllTitle: 'See All Remote Social Media Jobs',
    seeAllLink: '/remote-jobs/social-media',
  },
  {
    name: 'Sales',
    slug: 'sales',
    id: 11,
    heading: 'Remote Sales Jobs',
    headingLink: '/remote-jobs/sales',
    seeAllTitle: 'See All Remote Sales Jobs',
    seeAllLink: '/remote-jobs/sales',
  },
  {
    name: 'Account Manager',
    slug: 'account-manager',
    id: 12,
    heading: 'Remote Account Manager Jobs',
    headingLink: '/remote-jobs/account-manager',
    seeAllTitle: 'See All Remote Account Manager Jobs',
    seeAllLink: '/remote-jobs/account-manager',
  },
  {
    name: 'Medical Coding Jobs',
    slug: 'medical-coding',
    id: 13,
    heading: 'Remote Medical Coding Jobs',
    headingLink: '/remote-jobs/medical-coding',
    seeAllTitle: 'See All Remote Medical Coding Jobs',
    seeAllLink: '/remote-jobs/medical-coding',
  },
  {
    name: 'Human Resources & Recruiting',
    slug: 'recruiter',
    id: 14,
    heading: 'Remote Human Resources & Recruiting Jobs',
    headingLink: '/remote-jobs/recruiter',
    seeAllTitle: 'See All Remote Human Resources & Recruiting Jobs',
    seeAllLink: '/remote-jobs/recruiter',
  },
  {
    name: 'Writing',
    slug: 'writing',
    id: 15,
    heading: 'Remote Writing Jobs',
    headingLink: '/remote-jobs/writing',
    seeAllTitle: 'See All Remote Writing Jobs',
    seeAllLink: '/remote-jobs/writing',
  },
  {
    name: 'Administrative',
    slug: 'administrative',
    id: 16,
    heading: 'Remote Administrative Jobs',
    headingLink: '/remote-jobs/administrative',
    seeAllTitle: 'See All Remote Administrative Jobs',
    seeAllLink: '/remote-jobs/administrative',
  },
  {
    name: 'Banking Jobs',
    slug: 'banking',
    id: 17,
    heading: 'Remote Banking Jobs',
    headingLink: '/remote-jobs/banking',
    seeAllTitle: 'See All Remote Banking Jobs',
    seeAllLink: '/remote-jobs/banking',
  },
  {
    name: 'Call Center',
    slug: 'call-center',
    id: 18,
    heading: 'Remote Call Center Jobs',
    headingLink: '/remote-jobs/call-center',
    seeAllTitle: 'See All Remote Call Center Jobs',
    seeAllLink: '/remote-jobs/call-center',
  },
  {
    name: 'IT',
    slug: 'it',
    id: 19,
    heading: 'Remote IT Jobs',
    headingLink: '/remote-jobs/it',
    seeAllTitle: 'See All Remote IT Jobs',
    seeAllLink: '/remote-jobs/it',
  },
  {
    name: 'Cyber Security',
    slug: 'cyber-security',
    id: 20,
    heading: 'Remote Cyber Security Jobs',
    headingLink: '/remote-jobs/cyber-security',
    seeAllTitle: 'See All Remote Cyber Security Jobs',
    seeAllLink: '/remote-jobs/cyber-security',
  },
  {
    name: 'Education & Training',
    slug: 'education',
    id: 21,
    heading: 'Remote Education & Training Jobs',
    headingLink: '/remote-jobs/education',
    seeAllTitle: 'See All Remote Education & Training Jobs',
    seeAllLink: '/remote-jobs/education',
  },
  {
    name: 'Engineering',
    slug: 'engineering',
    id: 22,
    heading: 'Remote Engineering Jobs',
    headingLink: '/remote-jobs/engineering',
    seeAllTitle: 'See All Remote Engineering Jobs',
    seeAllLink: '/remote-jobs/engineering',
  },
  {
    name: 'Government',
    slug: 'government',
    id: 23,
    heading: 'Remote Government Jobs',
    headingLink: '/remote-jobs/government',
    seeAllTitle: 'See All Remote Government Jobs',
    seeAllLink: '/remote-jobs/government',
  },
  {
    name: 'Insurance',
    slug: 'insurance',
    id: 24,
    heading: 'Remote Insurance Jobs',
    headingLink: '/remote-jobs/insurance',
    seeAllTitle: 'See All Remote Insurance Jobs',
    seeAllLink: '/remote-jobs/insurance',
  },
  {
    name: 'UI/UX Design',
    slug: 'ui-ux-design',
    id: 25,
    heading: 'Remote UI/UX Design Jobs',
    headingLink: '/remote-jobs/ui-ux-design',
    seeAllTitle: 'See All Remote UI/UX Design Jobs',
    seeAllLink: '/remote-jobs/ui-ux-design',
  },
  {
    name: 'International',
    slug: 'international',
    id: 26,
    heading: 'Remote International Jobs',
    headingLink: '/remote-jobs/international',
    seeAllTitle: 'See All Remote International Jobs',
    seeAllLink: '/remote-jobs/international',
  },
  {
    name: 'Medical Billing Jobs',
    slug: 'medical-billing',
    id: 27,
    heading: 'Remote Medical Billing Jobs',
    headingLink: '/remote-jobs/medical-billing',
    seeAllTitle: 'See All Remote Medical Billing Jobs',
    seeAllLink: '/remote-jobs/medical-billing',
  },
  {
    name: 'Copywriting',
    slug: 'copywriting',
    id: 28,
    heading: 'Remote Copywriting Jobs',
    headingLink: '/remote-jobs/copywriting',
    seeAllTitle: 'See All Remote Copywriting Jobs',
    seeAllLink: '/remote-jobs/copywriting',
  },
  {
    name: 'Proofreading',
    slug: 'proofreading',
    id: 29,
    heading: 'Remote Proofreading Jobs',
    headingLink: '/remote-jobs/proofreading',
    seeAllTitle: 'See All Remote Proofreading Jobs',
    seeAllLink: '/remote-jobs/proofreading',
  },
  {
    name: 'Spanish',
    slug: 'spanish',
    id: 30,
    heading: 'Remote Spanish Jobs',
    headingLink: '/remote-jobs/spanish',
    seeAllTitle: 'See All Remote Spanish Jobs',
    seeAllLink: '/remote-jobs/spanish',
  },
  {
    name: 'Teaching',
    slug: 'online-teaching',
    id: 31,
    heading: 'Remote Teaching Jobs',
    headingLink: '/remote-jobs/online-teaching',
    seeAllTitle: 'See All Remote Teaching Jobs',
    seeAllLink: '/remote-jobs/online-teaching',
  },
  {
    name: 'Tutoring',
    slug: 'online-tutoring',
    id: 32,
    heading: 'Remote Tutoring Jobs',
    headingLink: '/remote-jobs/online-tutoring',
    seeAllTitle: 'See All Remote Tutoring Jobs',
    seeAllLink: '/remote-jobs/online-tutoring',
  },
  {
    name: 'Virtual Assistant',
    slug: 'virtual-assistant',
    id: 33,
    heading: 'Remote Virtual Assistant Jobs',
    headingLink: '/remote-jobs/virtual-assistant',
    seeAllTitle: 'See All Remote Virtual Assistant Jobs',
    seeAllLink: '/remote-jobs/virtual-assistant',
  },
  {
    name: 'Operations',
    slug: 'operations',
    id: 34,
    heading: 'Remote Operations Jobs',
    headingLink: '/remote-jobs/operations',
    seeAllTitle: 'See All Remote Operations Jobs',
    seeAllLink: '/remote-jobs/operations',
  },
  {
    name: 'Product Manager',
    slug: 'product-manager',
    id: 35,
    heading: 'Remote Product Manager Jobs',
    headingLink: '/remote-jobs/product-manager',
    seeAllTitle: 'See All Remote Product Manager Jobs',
    seeAllLink: '/remote-jobs/product-manager',
  },
  {
    name: 'Programming',
    slug: 'programming',
    id: 36,
    heading: 'Remote Programming Jobs',
    headingLink: '/remote-jobs/programming',
    seeAllTitle: 'See All Remote Programming Jobs',
    seeAllLink: '/remote-jobs/programming',
  },
  {
    name: 'Project Manager',
    slug: 'project-manager',
    id: 52,
    heading: 'Remote Project Manager Jobs',
    headingLink: '/remote-jobs/project-manager',
    seeAllTitle: 'See All Remote Project Manager Jobs',
    seeAllLink: '/remote-jobs/project-manager',
  },
  {
    name: 'QA Jobs',
    slug: 'qa',
    id: 37,
    heading: 'Remote QA Jobs',
    headingLink: '/remote-jobs/qa',
    seeAllTitle: 'See All Remote QA Jobs',
    seeAllLink: '/remote-jobs/qa',
  },
  {
    name: 'Ruby on Rails Jobs',
    slug: 'ruby-on-rails',
    id: 38,
    heading: 'Remote Ruby on Rails Jobs',
    headingLink: '/remote-jobs/ruby-on-rails',
    seeAllTitle: 'See All Remote Ruby on Rails Jobs',
    seeAllLink: '/remote-jobs/ruby-on-rails',
  },
  {
    name: 'Transcription',
    slug: 'transcription',
    id: 39,
    heading: 'Remote Transcription Jobs',
    headingLink: '/remote-jobs/transcription',
    seeAllTitle: 'See All Remote Transcription Jobs',
    seeAllLink: '/remote-jobs/transcription',
  },
  {
    name: 'Translator',
    slug: 'translator',
    id: 40,
    heading: 'Remote Translator Jobs',
    headingLink: '/remote-jobs/translator',
    seeAllTitle: 'See All Remote Translator Jobs',
    seeAllLink: '/remote-jobs/translator',
  },
  {
    name: 'Travel & Hospitality',
    slug: 'travel',
    id: 41,
    heading: 'Remote Travel & Hospitality Jobs',
    headingLink: '/remote-jobs/travel',
    seeAllTitle: 'See All Remote Travel & Hospitality Jobs',
    seeAllLink: '/remote-jobs/travel',
  },
  {
    name: 'Video Editing',
    slug: 'video-editing',
    id: 42,
    heading: 'Remote Video Editing Jobs',
    headingLink: '/remote-jobs/video-editing',
    seeAllTitle: 'See All Remote Video Editing Jobs',
    seeAllLink: '/remote-jobs/video-editing',
  },
  {
    name: 'Graphic Design',
    slug: 'graphic-design',
    id: 43,
    heading: 'Remote Graphic Design Jobs',
    headingLink: '/remote-jobs/graphic-design',
    seeAllTitle: 'See All Remote Graphic Design Jobs',
    seeAllLink: '/remote-jobs/graphic-design',
  },
  {
    name: 'Front End Developer Jobs',
    slug: 'front-end-developer',
    id: 44,
    heading: 'Remote Front End Developer Jobs',
    headingLink: '/remote-jobs/front-end-developer',
    seeAllTitle: 'See All Remote Front End Developer Jobs',
    seeAllLink: '/remote-jobs/front-end-developer',
  },
  {
    name: 'Data Science',
    slug: 'data-science',
    id: 45,
    heading: 'Remote Data Science Jobs',
    headingLink: '/remote-jobs/data-science',
    seeAllTitle: 'See All Remote Data Science Jobs',
    seeAllLink: '/remote-jobs/data-science',
  },
  {
    name: 'Full Stack Developer',
    slug: 'full-stack-developer',
    id: 46,
    heading: 'Remote Full Stack Developer Jobs',
    headingLink: '/remote-jobs/full-stack-developer',
    seeAllTitle: 'See All Remote Full Stack Developer Jobs',
    seeAllLink: '/remote-jobs/full-stack-developer',
  },
  {
    name: 'Web Developer',
    slug: 'web-developer',
    id: 47,
    heading: 'Remote Web Developer Jobs',
    headingLink: '/remote-jobs/web-developer',
    seeAllTitle: 'See All Remote Web Developer Jobs',
    seeAllLink: '/remote-jobs/web-developer',
  },
  {
    name: 'Developer',
    slug: 'developer',
    id: 48,
    heading: 'Remote Developer Jobs',
    headingLink: '/remote-jobs/developer',
    seeAllTitle: 'See All Remote Developer Jobs',
    seeAllLink: '/remote-jobs/developer',
  },
  {
    name: 'Design',
    slug: 'design',
    id: 49,
    heading: 'Remote Design Jobs',
    headingLink: '/remote-jobs/design',
    seeAllTitle: 'See All Remote Design Jobs',
    seeAllLink: '/remote-jobs/design',
  },
  {
    name: 'Software Engineer',
    slug: 'software-engineer',
    id: 50,
    heading: 'Remote Software Engineer Jobs',
    headingLink: '/remote-jobs/software-engineer',
    seeAllTitle: 'See All Remote Software Engineer Jobs',
    seeAllLink: '/remote-jobs/software-engineer',
  },
  {
    name: 'Back End Developer Jobs',
    slug: 'back-end-developer',
    id: 51,
    heading: 'Remote Back End Developer Jobs',
    headingLink: '/remote-jobs/back-end-developer',
    seeAllTitle: 'See All Remote Back End Developer Jobs',
    seeAllLink: '/remote-jobs/back-end-developer',
  },
  {
    name: 'Freelance Jobs',
    slug: 'freelance',
    id: 1003,
    heading: 'Remote Freelance Jobs',
    headingLink: '/remote-jobs/freelance',
    seeAllTitle: 'See All Remote Freelance Jobs',
    seeAllLink: '/remote-jobs/freelance',
  },
  {
    name: 'Part Time Jobs',
    slug: 'part-time',
    id: 1002,
    heading: 'Remote Part Time Jobs',
    headingLink: '/remote-jobs/part-time',
    seeAllTitle: 'See All Remote Part Time Jobs',
    seeAllLink: '/remote-jobs/part-time',
  },
  {
    name: 'Full Time Jobs',
    slug: 'full-time',
    id: 1001,
    heading: 'Remote Full Time Jobs',
    headingLink: '/remote-jobs/full-time',
    seeAllTitle: 'See All Remote Full Time Jobs',
    seeAllLink: '/remote-jobs/full-time',
  },
  {
    name: 'Entry Level Jobs',
    slug: 'Entry Level',
    id: 1004,
    heading: 'Remote Entry Level Jobs',
    headingLink: '/remote-jobs/entry-level',
    seeAllTitle: 'See All Remote Entry Level Jobs',
    seeAllLink: '/remote-jobs/entry-level',
  },
  {
    name: 'Find Remote Jobs',
    slug: 'Find Remote Jobs',
    id: 1005,
    heading: 'Remote Jobs ',
    headingLink: '/remote-jobs',
    seeAllTitle: 'Remote Jobs',
    seeAllLink: '/remote-jobs',
  },
  {
    name: 'Remote Fashion Jobs',
    slug: 'fashion-beauty',
    id: 53,
    heading: 'Remote Fashion Jobs',
    headingLink: '/remote-jobs/fashion-beauty',
    seeAllTitle: 'Remote Fashion Jobs',
    seeAllLink: '/remote-jobs/fashion-beauty',
  },
  {
    name: 'Remote Tech Support Jobs',
    slug: 'online-technical-support',
    id: 54,
    heading: 'Remote Tech Support Jobs',
    headingLink: '/remote-jobs/online-technical-support',
    seeAllTitle: 'Remote Tech Support Jobs',
    seeAllLink: '/remote-jobs/online-technical-support',
  },
  {
    name: 'Remote Dental Jobs',
    slug: 'dental',
    id: 55,
    heading: 'Remote Dental Jobs',
    headingLink: '/remote-jobs/dental',
    seeAllTitle: 'Remote Dental Jobs',
    seeAllLink: '/remote-jobs/dental',
  },
  {
    name: 'Remote Social Work Jobs',
    slug: 'social-work',
    id: 56,
    heading: 'Remote Social Work Jobs',
    headingLink: '/remote-jobs/social-work',
    seeAllTitle: 'Remote Social Work Jobs',
    seeAllLink: '/remote-jobs/social-work',
  },
  {
    name: 'Remote Communications Jobs',
    slug: 'communications',
    id: 57,
    heading: 'Remote Communications Jobs',
    headingLink: '/remote-jobs/communications',
    seeAllTitle: 'Remote Communications Jobs',
    seeAllLink: '/remote-jobs/communications',
  },
  {
    name: 'Remote Sports Jobs',
    slug: 'sports-fitness',
    id: 58,
    heading: 'Remote Sports Jobs',
    headingLink: '/remote-jobs/sports-fitness',
    seeAllTitle: 'Remote Sports Jobs',
    seeAllLink: '/remote-jobs/sports-fitness',
  },
  {
    name: 'Remote Library Jobs',
    slug: 'library',
    id: 59,
    heading: 'Remote Library Jobs',
    headingLink: '/remote-jobs/library',
    seeAllTitle: 'Remote Library Jobs',
    seeAllLink: '/remote-jobs/library',
  },
  {
    name: 'Remote Admin Jobs',
    slug: 'online-virtual-admin',
    id: 60,
    heading: 'Remote Admin Jobs',
    headingLink: '/remote-jobs/online-virtual-admin',
    seeAllTitle: 'Remote Admin Jobs',
    seeAllLink: '/remote-jobs/online-virtual-admin',
  },
  {
    name: 'Remote Non Profit Jobs',
    slug: 'nonprofit-philanthropy',
    id: 61,
    heading: 'Remote Non Profit Jobs',
    headingLink: '/remote-jobs/nonprofit-philanthropy',
    seeAllTitle: 'Remote Non Profit Jobs',
    seeAllLink: '/remote-jobs/nonprofit-philanthropy',
  },
  {
    name: 'Remote Research Jobs',
    slug: 'research-development',
    id: 62,
    heading: 'Remote Research Jobs',
    headingLink: '/remote-jobs/research-development',
    seeAllTitle: 'Remote Research Jobs',
    seeAllLink: '/remote-jobs/research-development',
  },
  {
    name: 'Remote Analyst Jobs',
    slug: 'analyst',
    id: 63,
    heading: 'Remote Analyst Jobs',
    headingLink: '/remote-jobs/analyst',
    seeAllTitle: 'Remote Analyst Jobs',
    seeAllLink: '/remote-jobs/analyst',
  },
  {
    name: 'Remote French Jobs',
    slug: 'online-french',
    id: 64,
    heading: 'Remote French Jobs',
    headingLink: '/remote-jobs/online-french',
    seeAllTitle: 'Remote French Jobs',
    seeAllLink: '/remote-jobs/online-french',
  },
  {
    name: 'Remote Japanese Jobs',
    slug: 'japanese',
    id: 65,
    heading: 'Remote Japanese Jobs',
    headingLink: '/remote-jobs/japanese',
    seeAllTitle: 'Remote Japanese Jobs',
    seeAllLink: '/remote-jobs/japanese',
  },
  {
    name: 'Remote German Jobs',
    slug: 'german',
    id: 66,
    heading: 'Remote German Jobs',
    headingLink: '/remote-jobs/german',
    seeAllTitle: 'Remote German Jobs',
    seeAllLink: '/remote-jobs/german',
  },
  {
    name: 'Remote Music Jobs',
    slug: 'online-music',
    id: 67,
    heading: 'Remote Music Jobs',
    headingLink: '/remote-jobs/online-music',
    seeAllTitle: 'Remote Music Jobs',
    seeAllLink: '/remote-jobs/online-music',
  },
  {
    name: 'Remote Healthcare Administration Jobs',
    slug: 'health-administration',
    id: 68,
    heading: 'Remote Healthcare Administration Jobs',
    headingLink: '/remote-jobs/health-administration',
    seeAllTitle: 'Remote Healthcare Administration Jobs',
    seeAllLink: '/remote-jobs/health-administration',
  },
  {
    name: 'Remote Art Jobs',
    slug: 'art-products-services',
    id: 69,
    heading: 'Remote Art Jobs',
    headingLink: '/remote-jobs/art-products-services',
    seeAllTitle: 'Remote Art Jobs',
    seeAllLink: '/remote-jobs/art-products-services',
  },
  {
    name: 'Remote Animal Jobs',
    slug: 'animals-wildlife',
    id: 70,
    heading: 'Remote Animal Jobs',
    headingLink: '/remote-jobs/animals-wildlife',
    seeAllTitle: 'Remote Animal Jobs',
    seeAllLink: '/remote-jobs/animals-wildlife',
  },
  {
    name: 'Remote Clinical Research Jobs',
    slug: 'medical-research',
    id: 71,
    heading: 'Remote Clinical Research Jobs',
    headingLink: '/remote-jobs/medical-research',
    seeAllTitle: 'Remote Clinical Research Jobs',
    seeAllLink: '/remote-jobs/medical-research',
  },
  {
    name: 'Remote Publishing Jobs',
    slug: 'publishing',
    id: 72,
    heading: 'Remote Publishing Jobs',
    headingLink: '/remote-jobs/publishing',
    seeAllTitle: 'Remote Publishing Jobs',
    seeAllLink: '/remote-jobs/publishing',
  },
  {
    name: 'Remote Real Estate Jobs',
    slug: 'real-estate',
    id: 73,
    heading: 'Remote Real Estate Jobs',
    headingLink: '/remote-jobs/real-estate',
    seeAllTitle: 'Remote Real Estate Jobs',
    seeAllLink: '/remote-jobs/real-estate',
  },
  {
    name: 'Remote Environmental Jobs',
    slug: 'environmental',
    id: 74,
    heading: 'Remote Environmental Jobs',
    headingLink: '/remote-jobs/environmental',
    seeAllTitle: 'Remote Environmental Jobs',
    seeAllLink: '/remote-jobs/environmental',
  },
  {
    name: 'Remote Appointment Setter Jobs',
    slug: 'online-appointment-setting',
    id: 75,
    heading: 'Remote Appointment Setter Jobs',
    headingLink: '/remote-jobs/online-appointment-setting',
    seeAllTitle: 'Remote Appointment Setter Jobs',
    seeAllLink: '/remote-jobs/online-appointment-setting',
  },
  {
    name: 'Remote Nutrition Jobs',
    slug: 'nutrition',
    id: 76,
    heading: 'Remote Nutrition Jobs',
    headingLink: '/remote-jobs/nutrition',
    seeAllTitle: 'Remote Nutrition Jobs',
    seeAllLink: '/remote-jobs/nutrition',
  },
  {
    name: 'Remote Consulting Jobs',
    slug: 'consulting-coaching',
    id: 77,
    heading: 'Remote Consulting Jobs',
    headingLink: '/remote-jobs/consulting-coaching',
    seeAllTitle: 'Remote Consulting Jobs',
    seeAllLink: '/remote-jobs/consulting-coaching',
  },
];

export const remoteJobsList = [
  '1',
  '3',
  '4',
  '49',
  '48',
  '5',
  '1004',
  '1003',
  '6',
  '14',
  '26',
  '19',
  '9',
  '1002',
  '52',
  '37',
  '11',
  '31',
  '39',
  '33',
  '15',
];

export const MeetNav = {
  heading: 'Meet Remote.co',
  footerLinks: [
    {
      name: 'About & Contact',
      route: '/about-us',
    },
    {
      name: 'CCPA/GDPR',
      route: '/personal-data',
    },
    {
      name: 'Do Not Sell or Share My Personal Information',
      route: '/dnss',
      relStatus: 'nofollow',
    },
    {
      name: 'Fraud Awareness',
      route: '/fraud-awareness',
    },
    {
      name: 'Press & Media',
      route: '/press',
    },
  ],
};

export const RemoteWorkQA = {
  heading: 'Remote Work Q&A',
  footerLinks: [
    {
      name: 'All Remote Companies',
      route: '/qa-leading-remote-companies',
    },
    {
      name: 'Why Remote',
      route: '/qa-leading-remote-companies/why-remote',
    },
    {
      name: 'Hiring Remotely',
      route: '/qa-leading-remote-companies/hiring-remotely',
    },
    {
      name: 'Managing Remotely',
      route: '/qa-leading-remote-companies/managing-remotely',
    },
    {
      name: 'Working Remotely',
      route: '/qa-leading-remote-companies/operating-remotely',
    },
    {
      name: 'Remote Worker Insights',
      route: '/qa-leading-remote-companies/remote-worker-insights',
    },
    {
      name: 'All Remote Workers',
      route: '/remote-workers',
    },
  ],
};

export const RemoteWorkArticles = [
  {
    heading: 'Remote Work Articles',
    footerLinks: [
      {
        name: 'All Articles',
        route: '/remote-work-blog',
      },
      {
        name: 'Why Go Remote',
        route: '/category/why-go-remote',
      },
      {
        name: 'Build a Remote Team',
        route: '/category/build-a-remote-team',
      },
      {
        name: 'Remote Management',
        route: '/category/remote-management',
      },
      {
        name: 'Work Remotely',
        route: '/category/work-remotely',
      },
    ],
  },
];

export const RemoteJobs = {
  heading: 'Remote Jobs',
  footerLinks: [
    {
      name: 'Find Remote Jobs',
      route: '/remote-jobs',
    },
    {
      name: 'Remote Accounting Jobs',
      route: '/remote-jobs/accounting',
    },
    {
      name: 'Remote Account Manager Jobs',
      route: '/remote-jobs/account-manager',
    },
    {
      name: 'Remote Bookkeeping Jobs',
      route: '/remote-jobs/bookkeeping',
    },
    {
      name: 'Remote Customer Service Jobs',
      route: '/remote-jobs/customer-service',
    },
    {
      name: 'Online Data Entry Jobs',
      route: '/remote-jobs/online-data-entry',
    },
    {
      name: 'Remote Data Science Jobs',
      route: '/remote-jobs/data-science',
    },
    {
      name: 'Remote Design Jobs',
      route: '/remote-jobs/design',
    },
    {
      name: 'Remote Developer Jobs',
      route: '/remote-jobs/developer',
    },
    {
      name: 'Online Editing Jobs',
      route: '/remote-jobs/online-editing',
    },
    {
      name: 'Remote Healthcare Jobs',
      route: '/remote-jobs/healthcare',
    },
    {
      name: 'Remote IT Jobs',
      route: '/remote-jobs/it',
    },
    {
      name: 'Remote Marketing Jobs',
      route: '/remote-jobs/marketing',
    },
    {
      name: 'Remote Medical Coding Jobs',
      route: '/remote-jobs/medical-coding',
    },
    {
      name: 'Remote Nursing Jobs',
      route: '/remote-jobs/nursing',
    },
    {
      name: 'Remote Legal Jobs',
      route: '/remote-jobs/legal',
    },
  ],
};

export const MoreRemoteJobs = {
  heading: 'More Remote Jobs',
  footerLinks: [
    {
      name: 'Remote Operations Jobs',
      route: '/remote-jobs/operations',
    },
    {
      name: 'Remote Product Manager Jobs',
      route: '/remote-jobs/product-manager',
    },
    {
      name: 'Remote Project Manager Jobs',
      route: '/remote-jobs/project-manager',
    },
    {
      name: 'Remote QA Jobs',
      route: '/remote-jobs/qa',
    },
    {
      name: 'Remote Recruiter Jobs',
      route: '/remote-jobs/recruiter',
    },
    {
      name: 'Remote Sales Jobs',
      route: '/remote-jobs/sales',
    },
    {
      name: 'Remote Social Media Jobs',
      route: '/remote-jobs/social-media',
    },
    {
      name: 'Online Teaching Jobs',
      route: '/remote-jobs/online-teaching',
    },
    {
      name: 'Virtual Assistant Jobs',
      route: '/remote-jobs/virtual-assistant',
    },
    {
      name: 'Remote Writing Jobs',
      route: '/remote-jobs/writing',
    },
    {
      name: 'Entry-Level Remote Jobs',
      route: '/remote-jobs/entry-level',
    },
    {
      name: 'Online Freelance Jobs',
      route: '/remote-jobs/freelance',
    },
    {
      name: 'International Remote Jobs',
      route: '/remote-jobs/international',
    },
    {
      name: 'Part-Time Remote Jobs',
      route: '/remote-jobs/part-time',
    },
  ],
};

export const commonMetas = [
  {
    article: {
      publisher: 'https://www.facebook.com/remote.co',
      modified_time: '2024-09-26T18:07:43+00:00',
      author: 'radmin',
    },
    preconnect: [
      'https://config.playwire.com',
      'https://cdn.intergi.com',
      'https://cdn.intergient.com',
      'https://securepubads.g.doubleclick.net',
      'https://cdn.playwire.com',
      'https://cdn.video.playwire.com',
      'https://z.moatads.com',
      'https://api.hostip.info',
    ],
    twitter: {
      card: 'summary_large_image',
      site: '@remote_co',
      label1: 'Est. reading time',
      data1: '1 minute',
    },
    fb: {
      app_id: '529576650555031',
    },
    'msvalidate.01': 'AAB4983B8CDBE98315805138D13BAB95',
  },
];

export const JOBS_COUNT_PATH: string = `${process.env.NEXT_PUBLIC_JOBS_URL_RJ}/v1/jobs/search?page=1&size=50`;

export const JOB_WORK_TYPE = {
  usNational: 'US National',
  remoteWork: '100% remote work',
  hybridRemoteWork: 'hybrid remote work',
  remote: 'Remote,',
  remoteIn: 'Remote in',
  hybrid: 'Hybrid,',
  hybridIn: 'Hybrid Remote in',
  remoteFromAnywhere: 'Remote from Anywhere',
  workFromAnywhere: 'work from anywhere',
};

export const getScrollHeightDrawerExp = () => {
  return document.documentElement.scrollHeight / 2;
};
