import {
  handleDrawerScroll,
  localStorageGetItem,
  readCookie,
} from '@components/common';
import {
  ApiBaseUrl,
  ApiVersion,
  CONFIG_OPTIONS,
  _CONSTANTS,
  getScrollHeightDrawerExp,
} from '@components/common/constant';
import { experimentViewedEvent } from '@components/common/event-tracker';
import { ApiEndPoint, ExperimentDetail } from '@components/shared.types';
import { experimentViewedCall, track } from '@utils/eventTrack';
import APIRequest from 'src/lib/baseAPI';
import {
  ABtestData,
  Experiment,
  RMCPortalExperiments,
  ScopeDevices,
  deviceTypeRegex,
  getExperimentById,
} from './experimentConstants';

interface CustomResponse {
  data: {
    experimentId: string;
    variant: number;
    variantname?: string;
    name?: string;
    isConducted: boolean;
    sendTraits?: boolean;
  } | null;
}

const apiRequest = APIRequest.getInstance();

function GetQueryString(field: string) {
  if (typeof window === 'undefined') return null;
  var href = window.location.href;
  var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
  var string = reg.exec(href);
  return string ? string[1] : null;
}

function createCookie(name: string, value: string, days?: number): void {
  let expires: string = '';
  if (days) {
    const date: Date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + value + expires + '; path=/'; // TODO: domain issue check
}

function getCookieValue(cookieString: string, key: string): string | null {
  const cookiesArray: string[] = cookieString.split('; ');

  for (const cookie of cookiesArray) {
    const [name, value] = cookie.split('=');
    if (name === key) {
      return value;
    }
  }

  return null;
}

export function getExperimentVariant(
  experimentID: string,
  cookieData?: string
): number {
  let variant: number = 0;
  if (experimentID == RMCPortalExperiments?.fjWizardExperimentSpa?.id) {
    return 3;
  }
  const cookieString: string | null = cookieData
    ? getCookieValue(cookieData, _CONSTANTS.CONDUCT_EXPERIMENT)
    : readCookie(_CONSTANTS.CONDUCT_EXPERIMENT) || '{}';

  const runTestCookie: string | null = cookieData
    ? getCookieValue(cookieData, ABtestData.RUNTEST)
    : readCookie(ABtestData.RUNTEST);
  if (runTestCookie) {
    const runTestValue: string[] = runTestCookie.split('_');
    if (runTestValue.length === 2 && runTestValue[0] === experimentID) {
      return parseInt(runTestValue[1]);
    }
  } else {
    const disableTestCookie: string | null = cookieData
      ? getCookieValue(cookieData, ABtestData.DISABLE_TESTS)
      : readCookie(ABtestData.DISABLE_TESTS);
    if (disableTestCookie && disableTestCookie === '1') {
      return 1;
    }
  }

  try {
    const parsedCookie: { [key: string]: { v: number } } = JSON.parse(
      cookieString || '{}'
    );

    if (parsedCookie && parsedCookie[experimentID]) {
      variant = parsedCookie[experimentID].v;
    }
  } catch (error) {
    console.error('Error while getting variant from cookie:', error);
  }

  return variant;
}

interface ExperimentData {
  v: number;
  s: number;
}

const syncCookie = (key: string, value: string | null): void => {
  if (value !== null) {
    createCookie(key, value);
  }
};

const updateExperimentStatus = (
  parsedCookie: Record<string, ExperimentData>
): void => {
  Object.entries(parsedCookie).forEach(([experimentId, data]) => {
    if (data.s === 0) {
      const expObj = getExperimentById(experimentId);
      if (expObj) {
        const visitorId = readCookie(_CONSTANTS.VSTR);
        experimentViewedCall({
          experimentId,
          variantname: expObj.variantNameArr[data.v],
          experimentName: expObj.name ?? '',
          vtsr: visitorId,
        });
      }
      data.s = 1;
    }
  });
};

export function SyncMixpanel(): void {
  const queryStringExperiment = GetQueryString(ABtestData.RUNTEST);
  const queryStringDisableTest = GetQueryString(ABtestData.DISABLE_TESTS);

  syncCookie(ABtestData.DISABLE_TESTS, queryStringDisableTest);
  syncCookie(ABtestData.RUNTEST, queryStringExperiment);

  if (typeof window !== 'undefined') {
    try {
      const cookieData = readCookie(_CONSTANTS.CONDUCT_EXPERIMENT);
      if (cookieData) {
        const parsedCookie: Record<string, ExperimentData> =
          JSON.parse(cookieData);
        updateExperimentStatus(parsedCookie);
        createCookie(
          _CONSTANTS.CONDUCT_EXPERIMENT,
          JSON.stringify(parsedCookie)
        );
      }
    } catch (error) {
      console.error('Error syncing Mixpanel:', error);
    }
  }
}

function getExperimentNameFromLocal(experimentId: string): string | false {
  const experiment = Object.values(RMCPortalExperiments).find(
    (exp) => exp.id === experimentId
  );
  return experiment ? experiment.name : false;
}

function getVariantNameFromLocal(
  experimentId: string,
  variantId: string
): string | false {
  const experiment = Object.values(RMCPortalExperiments).find(
    (exp) => exp.id === experimentId
  );
  if (
    experiment &&
    experiment.variantNameArr &&
    experiment.variantNameArr[variantId]
  ) {
    return experiment.variantNameArr[variantId];
  } else {
    return false;
  }
}

interface ExperimentDataForTrait {
  experimentId: string;
  variant: number;
}

export function triggerSPAUserExperimentTraits(
  experimentId: string,
  variant: number
) {
  if (typeof window === 'undefined') return;
  const experimentData: ExperimentDataForTrait = { experimentId, variant };
  let expKey =
    'Experiment: ' +
    (getExperimentNameFromLocal(experimentId) ||
      // @ts-ignore
      window.experiment.getExperimentName(experimentData.experimentId)
        .experimentName);
  let expObj: Record<string, string> = {};
  expObj[expKey] =
    getVariantNameFromLocal(experimentId, variant.toString()) ||
    // @ts-ignore
    window.experiment.getVariantName(
      experimentData.experimentId,
      experimentData.variant
    ).variantName;
  track('identify', expObj);
}

export function updateExperimentVariant(
  experimentId: string,
  newVariant: number,
  isSent?: number
): void {
  try {
    if (typeof window === 'undefined') return;
    let updatedCookieString = '';
    const parsedCookie = JSON.parse(readCookie('c_expt') || '{}');
    if (parsedCookie && parsedCookie[experimentId]) {
      parsedCookie[experimentId].v = newVariant;
      parsedCookie[experimentId].s = isSent !== undefined ? isSent : 0;
      updatedCookieString = JSON.stringify(parsedCookie);
    } else {
      const newExperiment = {
        [experimentId]: {
          v: newVariant,
          s: isSent !== undefined ? isSent : 0,
        },
      };

      const mergedCookie = { ...parsedCookie, ...newExperiment };
      updatedCookieString = JSON.stringify(mergedCookie);
    }
    createCookie(_CONSTANTS.CONDUCT_EXPERIMENT, updatedCookieString);
  } catch (error) {
    console.error('Error updating cookie:', error);
  }
}

export const conductExperimentV2API = async (
  UserId: string,
  experimentId: string
) => {
  const apiURL: ApiEndPoint = `/users/${UserId}/experiments/${experimentId}/conduct`;
  const experimentBody: any = {
    logTraits: true,
    conductForOldUsers: true,
    includeIterableIntegration: true,
    culture: 'string',
    skipGoverning: true,
  };
  const response = await apiRequest.post<any>(
    apiURL,
    experimentBody,
    process.env.NEXT_PUBLIC_EB_URL,
    ApiVersion.V2
  );
  return response.data;
};

export const getUsersAllExperimentAndUpdateCookie = async (
  userID: string,
  cookieHeader: string,
  onlyNeedResponse: boolean = false
) => {
  const url: ApiEndPoint = `/users/${userID}/experiments?portalCd=${CONFIG_OPTIONS.portalCD}&status=active`;
  const response: any = await apiRequest.get(
    url,
    cookieHeader || '',
    process.env.NEXT_PUBLIC_EB_URL,
    ApiVersion.V3
  );
  if (response && response.statusCode === 200 && !onlyNeedResponse) {
    processExperiments(response.data);
    createCookie(_CONSTANTS.LAYER_ID, response.data.layer);
  }
  return response.data;
};

function processExperiments(experiments: any): void {
  if (experiments?.experiments && experiments?.experiments.length > 0) {
    experiments?.experiments.forEach((experiment: any) => {
      const experimentUid: string = experiment.experiment_uid;
      const variant: number = experiment.variant;
      updateExperimentVariant(experimentUid, variant, 1);
    });
  }
}

export async function ConductExperiment(
  userId: string,
  experimentObj: any,
  referrer: any, // need referrer for event-tracking as wizard pages are soft loaded
  userAgentAtSSR?: string,
  cookieHeader?: string
) {
  let {
    experimentId,
    customResponse,
    userCreatedDate,
    exprConductionDate,
    experimentData,
    expData,
  } = getSpaExperimentConductionData(experimentObj, userAgentAtSSR);
  let variant: number = getExperimentVariant(experimentId);
  if (variant) {
    customResponse.data = {
      experimentId: experimentId,
      variant: variant,
      isConducted: false,
    };
  } else {
    if (isJSExperiment(expData)) {
      // @ts-ignore
      if (!window.experiment || !window.experiment.conductUserExperiment) {
        customResponse.data = {
          experimentId: experimentId,
          variant: 0,
          variantname: 'spa experiment js not found',
          name: 'spa experiment js not found for ' + experimentId,
          isConducted: false,
        };
      } else {
        if (experimentData.isNeedToGetOrConduct) {
          customResponse = handleExperimentNeedConduction(
            expData,
            userId,
            experimentId,
            customResponse
          );
        } else if (experimentData.variant != null) {
          customResponse.data = {
            experimentId: experimentId,
            variant: experimentData.variant,
            isConducted: false,
          };
        }
      }
    } else {
      customResponse = experimentObj.isVisitor
        ? await ConductVisitorBackendExperiment(
            userId,
            experimentObj,
            userAgentAtSSR
          )
        : await ConductExperimentV2(userId, experimentObj, userAgentAtSSR);
    }
  }

  // event tracking
  if (customResponse.data && customResponse.data.isConducted === true) {
    if (typeof window !== 'undefined') {
      experimentViewedEvent(
        customResponse.data.experimentId,
        expData?.name,
        customResponse.data.variantname,
        referrer
      );
    }
  }

  return customResponse;
}

function getSpaExperimentConductionData(
  experimentObj: any,
  userAgentAtSSR?: string,
  cookieHeader?: string
) {
  const experimentId = experimentObj.id;
  let experimentData = GetABTestConductionData(
    experimentId,
    false,
    userAgentAtSSR
  );
  let expData = Object.values(RMCPortalExperiments).find(
    (m) => m.id == experimentId
  );
  let exprConductionDate = undefined;
  let customResponse: CustomResponse = { data: null };
  let userCreatedDate = '';
  return {
    experimentId,
    customResponse,
    userCreatedDate,
    exprConductionDate,
    experimentData,
    expData,
  };
}

export function GetABTestConductionData(
  experimentId: string,
  stopCookieCreation: boolean = false,
  userAgentAtSSR?: string
) {
  let experimentData: {
    isNeedToGetOrConduct: boolean;
    variant: number | null;
    variantName?: string;
  } = {
    isNeedToGetOrConduct: true,
    variant: null, // variant can be assigned a number or null
  };

  if (experimentId == undefined || experimentId == null || experimentId == '') {
    return experimentData;
  }

  let expData = Object.values(RMCPortalExperiments).find(
    (m) => m.id == experimentId
  );
  if (!checkExperimentScope(expData, userAgentAtSSR)) {
    //No need to conduct/Get experiment as it does not follow device sope requirements.
    experimentData = {
      isNeedToGetOrConduct: false,
      variant: 0,
      variantName:
        'experiment not eligible for get or conduction due to country code or device type configuration.',
    };
    return experimentData;
  }
  let disableAbTest = {
    frmQS: GetQueryString(ABtestData.DISABLE_TESTS),
    frmCookie: readCookie(ABtestData.DISABLE_TESTS),
    writeCookie: false,
  };
  let runAbTests = {
    frmQS: GetQueryString(ABtestData.RUNTEST),
    frmCookie: readCookie(ABtestData.RUNTEST),
    writeCookie: false,
  };
  let IsDisableTest = disableAbTest.frmQS;
  if (
    (IsDisableTest == null || IsDisableTest == undefined) &&
    disableAbTest.frmCookie != null &&
    disableAbTest.frmCookie != undefined
  ) {
    IsDisableTest = disableAbTest.frmCookie; // means value is in cookie
  } else if (
    IsDisableTest != null &&
    IsDisableTest != undefined &&
    !stopCookieCreation
  ) {
    disableAbTest.writeCookie = true; // means value is in Query String. So write or update Cookie value
  }

  let abTestsValue = runAbTests.frmQS;
  if (
    (abTestsValue == null || abTestsValue == undefined) &&
    runAbTests.frmCookie
  ) {
    abTestsValue = runAbTests.frmCookie; // means value is in cookie
  } else if (abTestsValue && !stopCookieCreation) {
    runAbTests.writeCookie = true; // means value is in Query String. So write or update Cookie value
  }
  if (disableAbTest.writeCookie) {
    createCookie(ABtestData.DISABLE_TESTS, IsDisableTest!);
  }
  if (abTestsValue) {
    if (runAbTests.writeCookie) {
      createCookie(ABtestData.RUNTEST, abTestsValue); // create cookie if needed
    }
    let multiAbtests = abTestsValue.split(',');
    if (multiAbtests && multiAbtests.length > 0) {
      let selectedAbtest = multiAbtests.find(
        (id) => id.toLowerCase().indexOf(experimentId.toLowerCase()) > -1
      );
      if (selectedAbtest) {
        let keyIndexPair = selectedAbtest.split('_');
        if (keyIndexPair && keyIndexPair.length == 2) {
          experimentData.isNeedToGetOrConduct = false;
          experimentData.variant = parseInt(keyIndexPair[1]); //return its case index
          return experimentData;
        }
      }
    }
  }
  if (
    IsDisableTest != null &&
    IsDisableTest != undefined &&
    IsDisableTest == '1'
  ) {
    experimentData.isNeedToGetOrConduct = false; // ABtest is disabled, return 0 case index
    experimentData.variant = null;
    return experimentData;
  }
  return experimentData;
}

function isScopeEligibleForDeviceType(userAgent: string, expScope: string) {
  const scopeDevices: string[] = expScope.split('|');
  let isEligible = false;

  if (
    (deviceTypeRegex.mobile.test(userAgent) &&
      scopeDevices?.includes(ScopeDevices.mobile)) ||
    (deviceTypeRegex.desktop.test(userAgent) &&
      scopeDevices?.includes(ScopeDevices.desktop))
  ) {
    isEligible = true;
  }

  return isEligible;
}

function checkExperimentScope(experimentData: any, userAgentAtSSR?: string) {
  let isEligible = false;
  const expScope = experimentData?.scope?.toLowerCase();
  if (expScope) {
    if (expScope === '') {
      isEligible = true;
    } else if (expScope.length > 0) {
      let userAgent: string = '';
      if (typeof window !== 'undefined') {
        userAgent = navigator.userAgent.toLowerCase();
      } else if (typeof window === 'undefined' && userAgentAtSSR) {
        userAgent = userAgentAtSSR.toLowerCase();
      }
      isEligible = isScopeEligibleForDeviceType(userAgent, expScope);
    }
  }
  return isEligible;
}

function handleExperimentNeedConduction(
  expData: any,
  userId: string,
  experimentId: string,
  customResponse: CustomResponse
): CustomResponse {
  // let layerUid =
  //   process.env.NEXT_PUBLIC_ENV === 'stg' ||
  //   process.env.NEXT_PUBLIC_ENV === 'prod'
  //     ? '1f0b7a91-de32-44bb-ab4c-e2b71096e8e3'
  //     : '79c392dd-d999-4471-94fc-dd4b2600a2e4';
  let layerUid = readCookie(_CONSTANTS.LAYER_ID);
  // @ts-ignore
  let expResult = window.experiment.conductUserExperiment(
    userId,
    layerUid,
    experimentId
  );
  if (expResult && expResult.statusCode == 'OK') {
    let variantName = '';
    if (expData.variantNameArr) {
      variantName = expData.variantNameArr[expResult.variant];
    }
    if (!variantName) {
      if (expResult.variant == 1) {
        variantName = 'Baseline';
      } else if (expResult.variant == 2) {
        variantName = 'Variability Estimator';
      } else if (expResult.variant > 2) {
        variantName = expData.name + ' - Variation ' + expResult.variant;
      }
    }

    customResponse.data = {
      experimentId: experimentId,
      variant: expResult.variant,
      variantname: variantName,
      isConducted: true,
    };
    updateExperimentVariant(experimentId, expResult.variant);
    triggerSPAUserExperimentTraits(experimentId, expResult.variant);
    SyncMixpanel();
  }
  return customResponse;
}

function isJSExperiment(expData: any) {
  // @ts-ignore
  return expData?.isJSExperiment && window.experiment;
}

export async function ConductExperimentV2(
  userId: string,
  experimentObj: any,
  userAgentAtSSR?: string
  // culture: string = 'en-US'
): Promise<CustomResponse> {
  const experimentId = experimentObj.id;
  const {
    logTraits = true,
    conductForOldUsers = false,
    includeIterableIntegration = false,
  } = experimentObj;
  let customResponse: CustomResponse = { data: null };
  let experimentData = GetABTestConductionData(
    experimentId,
    false,
    userAgentAtSSR
  );
  let expData = Object.values(RMCPortalExperiments).find(
    (m) => m.id == experimentId
  );
  if (experimentData.isNeedToGetOrConduct) {
    const experimentDetails = await conductExperimentV2API(
      userId,
      experimentId
    );
    if (experimentDetails && experimentDetails.statusCode === 'ok') {
      updateExperimentVariant(experimentId, experimentDetails.data.variant);
      triggerSPAUserExperimentTraits(
        experimentId,
        experimentDetails.data.variant
      );
      experimentDetails.data.isConducted = true;
      customResponse = experimentDetails;
    }
  } else if (experimentData.variant != null) {
    customResponse.data = {
      experimentId: experimentId,
      variant: experimentData.variant,
      variantname: '',
      isConducted: false,
    };
  }
  return customResponse;
}

export function getUserID(): string | null {
  const cookieDataString = readCookie(_CONSTANTS.USER_STATUS);
  const cookieData = cookieDataString ? JSON.parse(cookieDataString) : null;
  return cookieData?.User?.UserId || null;
}

export function isGuest(): boolean {
  const cookieDataString = readCookie(_CONSTANTS.USER_STATUS);
  const cookieData = cookieDataString ? JSON.parse(cookieDataString) : null;
  return cookieData?.User?.Role == 0;
}

export function isLayerSet(): boolean {
  const cookieDataString = readCookie(_CONSTANTS.LAYER_ID);
  return !!cookieDataString;
}

export function getVariantForExperimentFromAPI(
  experimentData: any,
  experimentId: string
): number {
  const { experiments } = experimentData;

  for (const experiment of experiments) {
    if (experiment.experiment_uid === experimentId) {
      return experiment.variant;
    }
  }

  return 0;
}

export async function ConductVisitorBackendExperiment(
  visitId: string,
  experimentObj: any,
  userAgentAtSSR?: string,
  isSPA: boolean = false
): Promise<CustomResponse> {
  const experimentId = experimentObj.id;
  const customResponse: CustomResponse = { data: null };

  const experimentData = GetABTestConductionData(
    experimentId,
    false,
    userAgentAtSSR
  );

  if (!experimentData.isNeedToGetOrConduct) {
    if (experimentData.variant != null) {
      return {
        data: {
          experimentId,
          variant: experimentData.variant,
          variantname: experimentData.variantName,
          isConducted: false,
        },
      };
    }
    return customResponse;
  }

  const experimentDetails = await ConductVisitorBackendExperimentAPI(
    visitId,
    experimentId
  );

  if (
    experimentDetails &&
    (experimentDetails.statusCode === 'ok' ||
      experimentDetails.statusCode === 'created')
  ) {
    const { variant, experiment_uid, isConducted } = experimentDetails.data;

    updateExperimentVariant(experimentId, variant);

    if (isSPA) {
      triggerSPAUserExperimentTraits(experimentId, variant);
    }

    return {
      data: {
        experimentId: experiment_uid || experimentId,
        variant,
        variantname: experimentDetails.data.variantname || variant,
        isConducted: isConducted ?? true,
        sendTraits: true,
      },
    };
  }

  return customResponse;
}

export const ConductVisitorBackendExperimentAPI = async (
  visitId: string,
  experimentId: string
) => {
  const apiURL: string = `/visitors/${visitId}/experiments/${experimentId}/conduct?skipGoverning=true`;
  const experimentBody: any = {
    logTraits: true,
    conductForOldUsers: true,
    includeIterableIntegration: true,
    culture: 'string',
    skipGoverning: true,
  };
  const response = await apiRequest.post<any>(
    apiURL,
    experimentBody,
    ApiBaseUrl.BASE_URL_EB
  );
  return response.data;
};

export const getBackendVisiterExpDetail = async (
  deviceType: string,
  exptDetails: { mobile: Experiment; desktop: Experiment },
  reqHeaders: string = ''
) => {
  const exptObj =
    deviceType === 'mobile' ? exptDetails.mobile : exptDetails.desktop;
  const vstr = readCookie(_CONSTANTS.VSTR);
  const userAgent = reqHeaders;
  let exptResponse = await ConductVisitorBackendExperiment(
    vstr,
    exptObj,
    userAgent
  );
  const exptData: ExperimentDetail = {
    ...exptResponse.data,
    experimentName: exptObj.name,
    vtsr: vstr,
  };

  if (exptResponse?.data?.variant && exptResponse?.data?.variant > 0) {
    exptData.variantname = exptObj.variantNameArr[exptResponse.data.variant];
  }

  // Track Calls
  if (exptData?.sendTraits) {
    SyncMixpanel();
    experimentViewedCall(exptData);
  }

  return exptData;
};

export const runDrawerExperimentAddListener = async (
  deviceType: string,
  userAgent: string,
  setShowJobSearchSteps: Function,
  setIsSoftRegAlreadyShown?: Function,
  categoryPage: boolean = false,
  onScroll: boolean = false
) => {
  let exptData = await getBackendVisiterExpDetail(
    deviceType,
    {
      mobile: RMCPortalExperiments.rmcDrawerMobile,
      desktop: RMCPortalExperiments.rmcDrawerDesktop,
    },
    userAgent
  );

  if (exptData?.variant && exptData?.variant > 2) {
    window.addEventListener('scroll', () =>
      handleDrawerScroll(
        onScroll ? 0 : getScrollHeightDrawerExp(),
        setShowJobSearchSteps,
        setIsSoftRegAlreadyShown
      )
    );
  } else if (
    categoryPage &&
    !localStorageGetItem(_CONSTANTS.JOB_SEARCH_STEPS_POPUP_DISPLAYED)
  ) {
    window.addEventListener('scroll', () =>
      handleDrawerScroll(600, setShowJobSearchSteps, setIsSoftRegAlreadyShown)
    );
  }
};

export function JobsToWizardExperimentVariant(
  queryParams: any,
  deviceType: string
): string {
  const experimentRunning =
    queryParams?.eID ===
    (deviceType == 'mobile'
      ? '26461ece-0f1b-4963-8a36-b6d54c7f05ab'
      : 'd341793b-7f2e-4aef-a1fa-3608328b9ebf');
  if (experimentRunning) {
    return queryParams?.vID;
  }
  return '0';
}

export const getCDNExptDetails = (
  queryParams: any,
  deviceType: string,
  cookieHeader?: string
) => {
  let resEXPTData = {
    eID: '',
    vID: '',
  };
  let exptCookie = readCookie(_CONSTANTS.EW_EXP, cookieHeader);

  const expId = {
    desktop: 'd341793b-7f2e-4aef-a1fa-3608328b9ebf',
    mobile: '26461ece-0f1b-4963-8a36-b6d54c7f05ab',
  };

  if (exptCookie) {
    const cookieSplit = exptCookie.split(',');
    const expObjArr = cookieSplit.map((exp) => {
      const expArr = exp.split('_');
      return {
        eID: expArr[0],
        vID: expArr[1],
      };
    });
    return (
      expObjArr?.find(
        (exp) =>
          exp.eID === (deviceType === 'mobile' ? expId.mobile : expId.desktop)
      ) || {
        eID: '',
        vID: '',
      }
    );
  }

  if (Object.hasOwn(queryParams, 'eid') && Object.hasOwn(queryParams, 'vid')) {
    // ! VERIFY IF NEEDED for eid if not remove if statement
    resEXPTData.eID = queryParams.eid;
    resEXPTData.vID = queryParams.vid;
  } else if (
    Object.hasOwn(queryParams, 'eID') &&
    Object.hasOwn(queryParams, 'vID')
  ) {
    resEXPTData.eID = queryParams.eID;
    resEXPTData.vID = queryParams.eID;
  }
  return {
    eID: resEXPTData?.eID,
    vID: resEXPTData?.vID,
  };
};
