import { base_font, Colors } from '@styles/graphite/styled';

const JobPageDescriptionStyle = {
  Colors: {
    btnLgWrapper: Colors.black1,
    btnLgWrapperHover: Colors.white,
    JobGetStartedHeading: Colors.gray47,
    JobGetStartedDescription: Colors.gray47,
  },
  FontFamily: {
    btnLgWrapper: null,
  },
  FontSizes: {
    btnLgWrapper: base_font.toFixed(1) + 'rem',
    JobGetStartedHeading: (base_font * 2).toFixed(1) + 'rem',
    JobGetStartedHeadingForSearch: (base_font * 1.875).toFixed(1) + 'rem',
    JobGetStartedDescription: (base_font * 0.875).toFixed(1) + 'rem',
    JobGetStartedHeadingMB:(base_font * 1.625).toFixed(1) + 'rem',
    JobGetStartedDescriptionMB:(base_font * 0.75).toFixed(1) + 'rem',
  },
  BgColors: {
    btnLgWrapper: Colors.lightgreen1,
    btnLgWrapperHover: Colors.blue27,
    JobGetStartedColor: Colors.blue27,
    JobGetStartedColor2: Colors.azure,
    JobGetStartedBoxShadow: Colors.gray52,
    JobPagesDescription: Colors.gray47,
  },
  BdrColors: {
    btnLgWrapper: Colors.lightgreen1,
    btnLgWrapperHover: Colors.blue27,
    JobGetStartedColor: Colors.gray50,
    JobGetStartedHeading: Colors.lightgreen1,
    JobPagesDescription: Colors.gray50,
  },
};

export default JobPageDescriptionStyle;
