import { base_font, Colors } from '@styles/base/styled';

const JobSearchStepsPopupStyle = {
  Colors: {},
  FontSizes: {
    subHeading: (base_font * 1.25).toFixed(1) + 'rem',
    stepNumber: (base_font * 1.25).toFixed(1) + 'rem',
    stepsHeading: (base_font * 1.125).toFixed(1) + 'rem',
    StepsSubHeading: (base_font * 1.125).toFixed(1) + 'rem',
    heading: (base_font * 1.5).toFixed(1) + 'rem',
    subMobileHeading: base_font.toFixed(1) + 'rem',
    stepsMobileHeading: (base_font * 0.875).toFixed(1) + 'rem',
    StepsSubMobileHeading: (base_font * 0.875).toFixed(1) + 'rem',
    stepMobileNumber: (base_font * 0.875).toFixed(1) + 'rem',
  },
  BgColors: {
    stepNumber: Colors.black1,
  },
};

export default JobSearchStepsPopupStyle;
