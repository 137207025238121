import { base_font, Colors, FontFamily } from '@styles/base/styled';

const JobPageDescriptionStyle = {
  Colors: {
    btnLgWrapper: Colors.white,
    btnLgWrapperHover: Colors.white,
  },
  FontFamily: {
    btnLgWrapper: FontFamily.fontFamilyOpensense,
  },
  FontSizes: {
    btnLgWrapper: base_font.toFixed(1) + 'rem',
    JobGetStartedHeading: (base_font * 2).toFixed(1) + 'rem',
    JobGetStartedDescription: (base_font * 0.875).toFixed(1) + 'rem',
    JobGetStartedHeadingMB: (base_font * 1.625).toFixed(1) + 'rem',
    JobGetStartedDescriptionMB:(base_font * 0.75).toFixed(1) + 'rem',
    JobGetStartedHeadingForSearch: (base_font * 1.875).toFixed(1) + 'rem',
  },
  BgColors: {
    btnLgWrapper: Colors.black1,
    btnLgWrapperHover: Colors.black1,
    JobPagesDescription: Colors.gray5,
  },
  BdrColors: {
    btnLgWrapper: Colors.black1,
    btnLgWrapperHover: Colors.black1,
    JobPagesDescription: Colors.gray4,
  },
};

export default JobPageDescriptionStyle;
