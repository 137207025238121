import { Base_Global, Graphite_Global } from '@license-admin/boldfjcomponents';
import Theme from '@styles/base/theme';
import GraphiteTheme from '@styles/graphite/theme';
import BaseFont from './base/font.styled';
import Base_Font_Graphite from './graphite/font.styled';
import Base_Font_Sapphire from './sapphire/font.styled';
import Sapphire_Global from './sapphire/global.styled';
import SapphireTheme from './sapphire/theme';
type ThemeConfig = {
  MainTheme: any;
  GlobalCSS: any;
  BaseFont: any;
};
function getThemeConfig(theme: string): ThemeConfig {
  switch (theme) {
    case 'graphite':
      return {
        MainTheme: GraphiteTheme,
        GlobalCSS: Graphite_Global,
        BaseFont: Base_Font_Graphite,
      };
    case 'sapphire':
      return {
        MainTheme: SapphireTheme,
        GlobalCSS: Sapphire_Global,
        BaseFont: Base_Font_Sapphire,
      };
    case 'wlb':
      return { MainTheme: Theme, GlobalCSS: Base_Global, BaseFont: BaseFont };
    default:
      return {
        MainTheme: GraphiteTheme,
        GlobalCSS: Graphite_Global,
        BaseFont: Base_Font_Graphite,
      };
  }
}
export function CreateTheme(activeTheme?: any) {
  const getTheme = activeTheme?.toLowerCase();
  const themeConfig = getThemeConfig(getTheme);
  return {
    ...themeConfig,
  };
}
