import { Colors, FontFamily, base_font } from '@styles/sapphire/styled';

const CommonStyle = {
  BgColors: {
  },
  Colors: {
    BannerCloseBtn: Colors.black,
  },
  FontSizes: {
    DescriptionTxt: (base_font * 0.875).toFixed(1) + 'rem',
    BannerCloseBtn: (base_font * 1.15).toFixed(1) + 'rem',
    BannerCloseBtnSm: (base_font * 1.28).toFixed(1) + 'rem',
  },
  FontFamily: {
    BannerCloseBtn: FontFamily.fontAwesome,
  },
};

export default CommonStyle;
