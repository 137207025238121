import { Colors, FontFamily, base_font, fontWeight } from '@styles/base/styled';

const JobCardStyle = {
  Colors: {
    wrapper: Colors.black,
    iconWrap: Colors.gray1,
    featured: Colors.black,
    newJob: Colors.black,
    wrapperActive: Colors.white,
    jobAge: Colors.black,
    jobAgeNew: Colors.black1,
    saveJob: Colors.black,
    jobCompany: Colors.black,
    mutedtext: Colors.gray2,
    Headquartertxt: Colors.gray1,
    applyDate: Colors.black,
    trashCan: Colors.gray1,
    stickyIcon: Colors.black,
    stickySelected: Colors.gray2,
    appDate: Colors.black,
    deleteIcon: Colors.gray1,
    notehead: Colors.black,
    NoteHead: Colors.black,
    TextArea: Colors.black,
    colorDark: Colors.black,
    NoteLabel: Colors.gray1,
    accIcon: Colors.gray2,
    PopUpwrapper: Colors.black,
    promoHead: Colors.black,
    promoHeadSub: Colors.black,
    locationSpan: Colors.blue22,
    jobAgeDays: Colors.black,
    jobLocations: Colors.black,
    jobParagraph: Colors.black,
    NewJobsTag: Colors.white,
    JobDescPills: Colors.black,
    mbfilterwrap: Colors.black,
    mbfilterwrapselected: Colors.white,
    heading: Colors.black,
  },
  BgColors: {
    tagName: Colors.gray2,
    notesWrap: Colors.gray1,
    accIcon: Colors.gray1,
    promoRocketCta: Colors.gray1,
    badge: Colors.shadowSharp,
    contentBox: Colors.gray2,
    headingAfter: Colors.black,
    NewJobsTag: Colors.black,
    JobDescPills: Colors.gray2,
    searchFilterWrapper: Colors.gray5,
    jobAge: Colors.gray2,
    jobListItems:Colors.white
  },
  FontSizes: {
    title: base_font * 1.125 + 'rem',
    jobAge: (base_font * 0.875).toFixed(1) + 'rem',
    middleWrap: base_font * 0.875 + 'rem',
    companyWrapper: base_font * 0.875 + 'rem',
    trashIcon: (base_font * 0.8125).toFixed(1) + 'rem',
    deleteIcon: (base_font * 1.563).toFixed(1) + 'rem',
    stickyIcon: (base_font * 1.75).toFixed(1) + 'rem',
    timeIcon: base_font * 0.875 + 'rem',
    editText: (base_font * 0.875).toFixed(1) + 'rem',
    appliedOnText: (base_font * 0.875).toFixed(1) + 'rem',
    PopUpwrapper: (base_font * 1.375).toFixed(1) + 'rem',
    PopUpwrapperLarge: (base_font * 1.5).toFixed(1) + 'rem',
    accoladeName: (base_font * 0.875).toFixed(1) + 'rem',
    promoContent: (base_font * 0.875).toFixed(1) + 'rem',
    promoHead: (base_font * 1.0625).toFixed(1) + 'rem',
    promoHeadSub: (base_font * 0.875).toFixed(1) + 'rem',
    locationSpan: (base_font * 0.875).toFixed(1) + 'rem',
    shortDescription: (base_font * 0.75).toFixed(1) + 'rem',
    jobCompanyVariant: (base_font * 0.875).toFixed(1) + 'rem',
    tagList: (base_font * 0.75).toFixed(1) + 'rem',
    featured: (base_font * 0.75).toFixed(1) + 'rem',
    newTag: (base_font * 0.75).toFixed(1) + 'rem',
    NewJobsTag: (base_font * 0.625).toFixed(1) + 'rem',
    JobDescPills: (base_font * 0.875).toFixed(1) + 'rem',
    jobAgeNew: (base_font * 0.625).toFixed(1) + 'rem',
    tagName: (base_font * 0.875).toFixed(1) + 'rem',
    jobCardCountry: (base_font * 0.875).toFixed(1) + 'rem',
    companiesHeading: (base_font * 1.5).toFixed(1) + 'rem',
    companiesSubHeading: (base_font * 1.125).toFixed(1) + 'rem',
    saveJobPaidUser: (base_font * 1.125).toFixed(1) + 'rem',
    ViewMoreBtn: (base_font * 0.875).toFixed(1) + 'rem',
    JobLink: (base_font * 1.125).toFixed(1) + 'rem',
  },
  FontWeight: {
    medium: 500,
    title: fontWeight.semibold,
    jobAge: fontWeight.semibold,
    normal: 400,
    bold: 700,
    accoladeName: 500,
    tagName: fontWeight.normal,
  },
  BdrColors: {
    wrapperFeatured: Colors.black,
    wrapper: Colors.black,
    companyWrapper: Colors.gray2,
    wrapperActive: Colors.black,
    borderNotes: Colors.gray2,
    textAreaWrap: Colors.gray2,
    accoladeImg: Colors.gray2,
    accIcon: Colors.gray2,
    promoRocketCta: Colors.gray2,
    seeAllLink: Colors.black,
    ArrowIcon: Colors.black,
    BtnArrowIcon: Colors.white,
    BreadcrumbOuterWrapper: Colors.black,
    mbfilterwrap: Colors.black,
    mbfilterwrapselected: Colors.black,
    jobListItems:Colors.black
  },
  BdrRadius: {
    taglist: '9.9rem;',
  },
  FontFamily: {
    companiesSubHeading: FontFamily.fontFamilyOpensense,
    companiesHeading: FontFamily.fontFamilyOpensense,
    seeAllLink: FontFamily.fontFamilyOpensense,
    JobsHeading: FontFamily.fontFamilyRaleway,
  },
};

export default JobCardStyle;
