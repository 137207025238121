import CompAwards from '@styled/base/awardAccolades';
import BreadcrumbStyle from '@styled/base/common/breadcrumb';
import BannerSection from '@styled/base/common/bannerSection';
import BtnStyle from '@styled/base/common/button';
import CarouselStyle from '@styled/base/common/carousel';
import FilterBtnStyle from '@styled/base/common/filter';
import FormCheckStyle from '@styled/base/common/formCheck';
import FormGroupStyle from '@styled/base/common/formGroup';
import JobPltfrmStyle from '@styled/base/common/gigJobPlatforms';
import jobGuideInfoStyle from '@styled/base/common/jobGuidePopover';
import PltfrmTypeStyle from '@styled/base/common/jobPlatformTypes';
import PillsStyle from '@styled/base/common/linkPills';
import SearchFilterStyle from '@styled/base/common/searchFilter';
import ToasterSectionStyle from '@styled/base/common/toaster';
import toolTipStyle from '@styled/base/common/toolTip';
import CompanyCtaPopupStyle from '@styled/base/companies/companyCtaPopup';
import CatLinkStyle from '@styled/base/dropableCategories/index';
import IndPlatformStyle from '@styled/base/gigs/companyDetails';
import BenefitStyle from '@styled/base/homePage/benefits';
import GreatEmployer from '@styled/base/homePage/greatEmployer';
import HeroBannerStyle from '@styled/base/homePage/heroBanner';
import MobBannerStyle from '@styled/base/homePage/mobHeroBanner';
import NewsBlogStyle from '@styled/base/homePage/newsBlog';
import NotOrdinaryStyle from '@styled/base/homePage/notOrdinarySection';
import PopularJobSearch from '@styled/base/homePage/popularJobSearch';
import QualityBannerStyle from '@styled/base/homePage/remoteJobQualityBanner';
import TestimonialStyle from '@styled/base/homePage/testimonial';
import JobAction from '@styled/base/jobAction';
import JobCardStyle from '@styled/base/jobCard';
import JobDetailsStyle from '@styled/base/jobDetails';
import JobInfoStyle from '@styled/base/jobInfo';
import PltfrmDetailStyle from '@styled/base/jobPlatforms';
import PltfrmCatgStyle from '@styled/base/jobPlatforms/category';
import EcoPlatformStyle from '@styled/base/jobPlatforms/featuredPlatform';
import HeadBannerStyle from '@styled/base/jobPlatforms/headingBanner';
import JobSearch from '@styled/base/jobSearch';
import JobSection from '@styled/base/jobSection';
import LoginStyle from '@styled/base/login';
import NavStyle from '@styled/base/navItems';
import UnAuthStyle from '@styled/base/navItems/unAuth';
import RegisterUserStyle from '@styled/base/registerUser';
import JobSearchStyle from '@styles/base/components/common/remoteJobSearch';
import HeroSecStyle from '@styles/base/components/common/searchPillsBanner';
import UnAuthHeroSecStyle from '@styles/base/components/searchPillsBannerUnauth';
import { DefaultTheme } from 'styled-components';
import SignUpSoftRegPopupStyle from './components/SoftRegPopup';
import JobSearchStepsPopupStyle from './components/JobSearchStepsPopup';
import JobPageDescriptionStyle from './components/JobPageDescription';
import AdvanceJobStyle from './components/advanceJobSearches';
import CommonStyle from './components/common';
import FormRadioStyle from './components/common/formRadio';
import FormControlStyle from './components/common/fromSelect';
import HeaderSectionStyle from './components/common/headerSection';
import ChipsStyle from './components/common/mulitsSelectChips';
import MultiFilterStyle from './components/common/multiSelectdropdown';
import PaginationStyle from './components/common/pagination';
import ModalStyle from './components/common/popUp';
import ProgressStyle from './components/common/progressBar';
import RadialProgStyle from './components/common/radialProgress';
import SectionCollapse from './components/common/sectionCollapsible';
import SidebarStyle from './components/common/sidebarWidget';
import telecommuteJobStyle from './components/common/telecommuteJob';
import CurrHiringTypeStyle from './components/common/widgets/currentlyHiringType';
import PromoBannerStyle from './components/common/widgets/promoBanner';
import RelatedCompStyle from './components/common/widgets/relatedCompanySearch';
import SimilarJobsStyle from './components/common/widgets/similarJobs';
import SuccessStoriesStyle from './components/common/widgets/successStories';
import UpComingEventStyle from './components/common/widgets/upComingEvents';
import IndvCompStyle from './components/companies';
import CompRedesign from './components/companies/compRedesignMob';
import BannerStyle from './components/companies/companiesHiringBanner';
import CompanyGuideStyle from './components/companies/companyGuide';
import OverviewStyle from './components/companies/overviewSuccess';
import PastJobsStyle from './components/companies/pastJobs';
import UnAuthPopupStyle from './components/companies/unAuthPopup';
import CompActivityStyle from './components/companyActivity';
import CompanyAwardsStyle from './components/companyAwards';
import CompanyFlexibilityStyle from './components/companyFlexibility';
import CompanyHiringStyle from './components/companyHiring';
import CompanySearchesStyle from './components/companySearches';
import EmployersStyle from './components/employers';
import ExitPopUpStyle from './components/exitPopUp';
import FooterStyle from './components/footer';
import HeaderStyle from './components/header';
import JobInfoFooterStyle from './components/jobInfoFooter';
import JobNoteStyle from './components/jobNoteStyle';
import JobSavedSearchStyle from './components/jobSearchActivity';
import TopCatgStyle from './components/latestCategories';
import LayoutStyle from './components/layout';
import MapStyle from './components/map';
import ApplicationStyle from './components/myJobApplications';
import CareerWidgetStyle from './components/myJobApplications/jobWidgets/careerAdvice';
import SearchesChecklist from './components/myJobApplications/jobWidgets/jobSearchChecklists';
import SkillsWidgetStyle from './components/myJobApplications/jobWidgets/popularSkillTests';
import AuthStyle from './components/navItems/auth';
import NewProfileFormStyle from './components/newProfileForm';
import PlatformInfoStyle from './components/platformInfo';
import ResearchCompanyStyle from './components/researchCompanies';
import ResumeProfileStyle from './components/resumeProfile';
import SavedSearches from './components/savedSearches';
import SignUpStyle from './components/signUp';
import FormCtrlStyle from './helpers/controls';

const BaseTheme: DefaultTheme = {
  JobCardStyle,
  MultiFilterStyle,
  ChipsStyle,
  NavStyle,
  UnAuthStyle,
  IndPlatformStyle,
  CompActivityStyle,
  NotOrdinaryStyle,
  RelatedCompStyle,
  TestimonialStyle,
  PltfrmDetailStyle,
  SignUpSoftRegPopupStyle,
  CompAwards,
  PltfrmCatgStyle,
  BenefitStyle,
  HeadBannerStyle,
  CompRedesign,
  JobPltfrmStyle,
  PltfrmTypeStyle,
  EcoPlatformStyle,
  BtnStyle,
  NewProfileFormStyle,
  HeaderStyle,
  ModalStyle,
  JobInfoFooterStyle,
  SuccessStoriesStyle,
  CarouselStyle,
  RadialProgStyle,
  JobSearchStyle,
  HeroSecStyle,
  UpComingEventStyle,
  NewsBlogStyle,
  CurrHiringTypeStyle,
  PillsStyle,
  SearchFilterStyle,
  ToasterSectionStyle,
  QualityBannerStyle,
  ApplicationStyle,
  toolTipStyle,
  jobGuideInfoStyle,
  FilterBtnStyle,
  HeroBannerStyle,
  UnAuthHeroSecStyle,
  CompanyFlexibilityStyle,
  PopularJobSearch,
  TopCatgStyle,
  SectionCollapse,
  CatLinkStyle,
  BreadcrumbStyle,
  BannerSection,
  JobDetailsStyle,
  JobInfoStyle,
  RegisterUserStyle,
  LoginStyle,
  MobBannerStyle,
  CompanyHiringStyle,
  CompanySearchesStyle,
  CompanyAwardsStyle,
  ResearchCompanyStyle,
  IndvCompStyle,
  MapStyle,
  PlatformInfoStyle,
  CompanyGuideStyle,
  OverviewStyle,
  PastJobsStyle,
  BannerStyle,
  CompanyCtaPopupStyle,
  FormControlStyle,
  GreatEmployer,
  AuthStyle,
  FooterStyle,
  FormCheckStyle,
  FormGroupStyle,
  FormCtrlStyle,
  HeaderSectionStyle,
  JobSavedSearchStyle,
  ProgressStyle,
  ResumeProfileStyle,
  PaginationStyle,
  SidebarStyle,
  PromoBannerStyle,
  SavedSearches,
  SearchesChecklist,
  CareerWidgetStyle,
  SkillsWidgetStyle,
  telecommuteJobStyle,
  AdvanceJobStyle,
  SimilarJobsStyle,
  FormRadioStyle,
  EmployersStyle,
  UnAuthPopupStyle,
  JobNoteStyle,
  ExitPopUpStyle,
  SignUpStyle,
  LayoutStyle,
  JobSearch,
  JobAction,
  JobSection,
  CommonStyle,
  JobSearchStepsPopupStyle,
  JobPageDescriptionStyle
};

export default BaseTheme;
