export interface Experiment {
  id: string;
  name: string;
  isJSExperiment: boolean;
  isVisitor: boolean;
  logTraits: boolean;
  conductForOldUsers: boolean;
  getAfterSwap: boolean;
  scope: string;
  variantNameArr: { [key: string]: string };
}

export const ABtestData = {
  DISABLE_TESTS: 'disabletests',
  RUNTEST: 'runtest',
};

interface PortalExperiments {
  [key: string]: Experiment;
}

export const deviceTypeRegex = {
  mobile: /mobile|android|iphone|ipad|ipod|Android|BlackBerry/i,
  desktop: /windows|macintosh|linux|mac os/i,
};

export enum ScopeDevices {
  mobile = 'mobile',
  desktop = 'desktop',
}

export const RMCPortalExperiments: PortalExperiments = {
  rmcLocationFieldDesktop: {
    id: '4d3a4b74-85a7-492b-9dfe-9060708471b3',
    name: 'RMC Simplify location Desktop V1 CVR',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'desktop',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Location simplified',
    },
  },
  rmcLocationFieldMobile: {
    id: 'ac99a2c9-e3ac-4dd8-8ee8-acda9f7a695f',
    name: 'RMC Simplify location Mobile V1 CVR',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'mobile',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Location simplified',
    },
  },
  rmcDrawerDesktop: {
    id: '0fc34b52-9e71-4103-b197-d6905df86dfb',
    name: 'RMC Drawer Desktop V1 CVR',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'desktop',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Aggressive Drawer',
    },
  },
  rmcDrawerMobile: {
    id: 'e6a132b5-971b-416b-a803-5ad80b712bec',
    name: 'RMC Drawer Mobile V1 CVR',
    isJSExperiment: false,
    isVisitor: true,
    logTraits: true,
    conductForOldUsers: false,
    getAfterSwap: false,
    scope: 'mobile',
    variantNameArr: {
      '1': 'Baseline',
      '2': 'Variability Estimator',
      '3': 'Aggressive Drawer',
    },
  },
};

export function getExperimentById(id: string): Experiment | undefined {
  return Object.values(RMCPortalExperiments).find(
    (experiment) => experiment.id === id
  );
}
