import {
  clearCookies,
  clearlocalStorage,
  getClientSettingValue,
  readCookie,
  setClientSettings,
} from '@components/common';
import BillingFooter from '@components/common/billingFooter';
import {
  AboutJobsAuth,
  AboutJobsAuthReg,
  JOBS_COUNT_PATH,
  MeetNav,
  MoreRemoteJobs,
  RemoteJobs,
  RemoteWorkArticles,
  RemoteWorkQA,
  _CONSTANTS,
} from '@components/common/constant';
import FooterRibbon from '@components/common/footerRibbon';
import { GlobalContext } from '@components/globalContext';
import { IDeviceType, IUserID } from '@components/shared.types';
import {
  SimpleFooter,
  SimpleHeader,
  useDeviceType,
} from '@license-admin/boldfjcomponents';
import { isPortalContent, isRCPortal, siteLogo } from 'helper';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { Children, ReactNode, useContext, useEffect, useState } from 'react';
import { newRelicJS } from '../../utils/newRelicClient';
import {
  ContentWrapper,
  FeaturedWrap,
  LeftSec,
  RightSection,
  SkipLink,
  StyledContainerMd,
  Title,
} from './styled';

interface LayoutProps {
  children: ReactNode;
  shadow?: boolean;
  smallAside?: boolean;
  blog?: boolean;
  padding?: boolean;
  gridCol?: string;
  rightSideVisible?: boolean;
  isLoggedIn?: boolean | null;
  isEmployerLogin?: boolean | null;
  isEmployerLoginPage?: boolean;
  headerProps?: Array<string>;
  deviceType?: IDeviceType;
  isPremiumUser?: boolean;
  mobApplyGrid?: boolean;
  headerSearch?: boolean;
  columnSpace?: string;
  isPartnerPage?: boolean;
  partnerImgPath?: string;
  noHeaderFooter?: boolean;
  leftSectionrole?: string;
  rightSectionrole?: string;
  isBillingFooter?: boolean;
  isHideFooter?: boolean;
  fullwidth?: boolean;
  isRMCPage?: boolean;
  nextJsApp?: any;
  isRCPortal?: any;
  isRCJobsDetails?: boolean;
  style?: any;
  handleThemeChange?: any;
  themeType?: string;
  isSignupPage?: boolean;
  noPadding?: boolean;
}

interface FooterLink {
  name: string;
  route: string;
}

interface FooterSection {
  heading: string;
  footerLinks: FooterLink[];
}

export const Layout = (props: LayoutProps) => {
  const defaultFirstName = 'My';
  const defaultLastName = 'Account';
  const { isUserLoggedIn, getUpdateUserDetail } = useContext(GlobalContext);
  const isMobileView = useDeviceType(props.deviceType) === 'mobile';
  const [userName, setUserName] = useState<string>('');
  const [rootURL, setRootURL] = useState<string>('');
  const [userIdsNav, setUserIdsNav] = useState<IUserID | undefined>(undefined);
  const [userEmail, setUserEmail] = useState<string>('');
  const [aboutJobsAuth, setAboutJobsAuth] = useState<any>();
  const [aboutJobsRex, setAboutJobsRex] = useState<any>();
  const router = useRouter();
  const blob_img_path = process.env.NEXT_PUBLIC_CONTENT_URL + '/images';
  const loggedInStatus = (props && props.isLoggedIn) || isUserLoggedIn;
  const SiteLogo = {
    height: 60,
    width: 133,
    logoSrc: `${blob_img_path}/` + siteLogo,
    title: `${isPortalContent}: Search The Best Remote Work From Home and Flexible Jobs`,
  };

  const [remoteWorkArticlesNav, setRemoteWorkArticlesNav] =
    useState(RemoteWorkArticles);

  const [isBundleExperiment, setIsBundleExperiment] = useState(false);

  const FooterNav = [
    MeetNav,
    RemoteWorkQA,
    remoteWorkArticlesNav,
    RemoteJobs,
    MoreRemoteJobs,
  ];

  const logoutUser = async () => {
    if (window && window.LOGIN) {
      await window.LOGIN.Accounts.logOutV2();
      try {
        // window.localStorage.clear();
        clearlocalStorage();
        window.sessionStorage.clear();
        clearCookies();
      } catch (error) {
        // Empty catch block
        // This catch block intentionally left empty
      }
      router.push('/login');
      getUpdateUserDetail();
    }
  };

  // Filter About Jobs navigation based on country code
  const returnAboutNav = (countryCode: string = '') => {
    if (countryCode !== 'US') {
      let tempAuthArray = AboutJobsAuth;
      let tempRexArray = AboutJobsAuthReg;
      let filteredAboutNavAuth = AboutJobsAuth.children?.filter(
        (item) => item.route !== '/dnss-form'
      );
      let filteredAboutNavRex = AboutJobsAuthReg.children?.filter(
        (item) => item.route !== '/dnss-form'
      );

      tempAuthArray.children = filteredAboutNavAuth;
      tempRexArray.children = filteredAboutNavRex;
      setAboutJobsAuth(tempAuthArray);
      setAboutJobsRex(tempRexArray);
    } else {
      setAboutJobsAuth(AboutJobsAuth);
      setAboutJobsRex(AboutJobsAuthReg);
    }
  };

  const returnFooterNav = (countryCode: string = '') => {
    if (countryCode !== 'US') {
      const filteredRemoteWorkArticles = remoteWorkArticlesNav.map((data) => ({
        ...data,
        footerLinks: data.footerLinks.filter((item) => item.route !== '/dnss'),
      }));
      setRemoteWorkArticlesNav(filteredRemoteWorkArticles);
    }
  };

  useEffect(() => {
    const getUserInfoClaims = async () => {
      const claims: any = getClientSettingValue(_CONSTANTS.CLAIMS);
      let userInfo: any = claims && (await JSON.parse(claims));
      if (!userInfo && window.LOGIN) {
        let { claims } = await window.LOGIN.Accounts.getClaims();
        userInfo = claims;
        setClientSettings({
          key: _CONSTANTS.CLAIMS,
          value: JSON.stringify(claims),
        });
      }
      const { firstName, lastName, email, memberid, user_uid } = userInfo || {};
      if (firstName || lastName) {
        setUserName(`${firstName} ${lastName}`);
      } else {
        setUserName(`${defaultFirstName} ${defaultLastName}`);
      }
      setUserIdsNav({
        memberId: parseInt(memberid),
        userId: user_uid,
      });
      setUserEmail(email);
      getUpdateUserDetail();
    };

    (loggedInStatus || isUserLoggedIn) && getUserInfoClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInStatus, isUserLoggedIn]);

  useEffect(() => {
    // let cachedData = localStorageGetItem('v1/user/countryclaims');
    // if (cachedData) {
    //   let res = JSON.parse(cachedData);
    //   returnAboutNav(res?.data?.countryCode?.toUpperCase() || '');
    //   returnFooterNav(res?.data?.countryCode?.toUpperCase() || '');
    // } else {
    //   getLocation(userEmail).then((response: any) => {
    //     returnAboutNav(response?.data?.countryCode?.toUpperCase() || '');
    //     returnFooterNav(response?.data?.countryCode?.toUpperCase() || '');
    //   });
    // }
    returnAboutNav('US');
    returnFooterNav('US');
  }, [userEmail]);

  useEffect(() => {
    if (window && window.location) {
      setRootURL(window.location.origin);
    }

    let exptCookie = readCookie(_CONSTANTS.EW_EXP);

    const expId = {
      desktop: 'd341793b-7f2e-4aef-a1fa-3608328b9ebf',
      mobile: '26461ece-0f1b-4963-8a36-b6d54c7f05ab',
    };

    if (exptCookie) {
      const cookieSplit = exptCookie.split(',');
      const expObjArr = cookieSplit.map((exp) => {
        const expArr = exp.split('_');
        return {
          eID: expArr[0],
          vID: expArr[1],
        };
      });
      const expt = expObjArr?.find(
        (exp) => exp.eID === (isMobileView ? expId.mobile : expId.desktop)
      );
      setIsBundleExperiment(expt?.vID == '3');
    }
  }, []);
  const isnextJsApp: any =
    ['qa', 'reg'].indexOf(process.env.NEXT_PUBLIC_ENV || '') > -1
      ? true
      : false;
  const routePath =
    router.pathname ===
      '/remote-jobs/legitimate-work-from-home-jobs-hiring-now' ||
    router.pathname === '/remote-jobs/search' ||
    router.pathname === '/wlb/remote-jobs/search'
      ? '/remote-jobs/search'
      : router.pathname === '/remote-jobs'
      ? '/remote-jobs'
      : '';
  return (
    <ContentWrapper isRCPortal={isRCPortal}>
      <SkipLink href="#content" className="skip-link screen-reader-text">
        Skip to content
      </SkipLink>
      {!props.noHeaderFooter && (
        <SimpleHeader
          logoutUser={logoutUser}
          userIds={userIdsNav}
          userName={userName}
          isAuthUser={loggedInStatus}
          isPremium={props.isPremiumUser}
          isMobileView={isMobileView}
          routePath={routePath}
          jobCountPath={JOBS_COUNT_PATH}
          isNextJsApp={isnextJsApp}
          handleThemeChange={props.handleThemeChange}
          themeType={props.themeType}
          isBunldeTestExperiment={isBundleExperiment}
        />
      )}
      <StyledContainerMd
        as={'main'}
        layout
        id="content"
        role="main"
        fullwidth={props.fullwidth}
        isPrimaryContainer={isRCPortal}
      >
        {props.children}
      </StyledContainerMd>

      {!props.noHeaderFooter && !props.isHideFooter ? (
        !props.isBillingFooter ? (
          <SimpleFooter
            isMobileView={isMobileView}
            nextJsApp={isnextJsApp}
            footerNavLinks={FooterNav as FooterSection[][]}
          />
        ) : (
          <BillingFooter />
        )
      ) : null}
      {props.isEmployerLoginPage && <FooterRibbon />}
      <Title>Next App</Title>
      {process.env.NEXT_PUBLIC_ENV == 'prod' && (
        <Script
          id="newrelic-js"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{ __html: newRelicJS }}
        />
      )}
    </ContentWrapper>
  );
};

export const FlexLayout = (props: LayoutProps) => {
  const isMobileView = useDeviceType(props.deviceType) === 'mobile';
  const [left, right] = Children.toArray(props.children);

  return (
    <FeaturedWrap {...props}>
      <LeftSec role={props.leftSectionrole} className="left" {...props}>
        {left}
      </LeftSec>

      {(isMobileView && !props.rightSideVisible) || !right ? null : (
        <RightSection
          role={props.rightSectionrole}
          className="right"
          isRMCPage={props.isRMCPage}
          isSignupPage={props.isSignupPage}
        >
          {right}
        </RightSection>
      )}
    </FeaturedWrap>
  );
};
